/* tslint:disable */
/* eslint-disable */
/**
 * Consigprev API
 * API do sistema de consignações previdenciárias - ConsigPrev
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from '../configuration'
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios'
import globalAxios from 'axios'
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from '../common'
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base'
// @ts-ignore
import { AprovarProposta } from '../models'
// @ts-ignore
import { AssinaDocumentoDto } from '../models'
// @ts-ignore
import { CalcularProposta } from '../models'
// @ts-ignore
import { ConsultaCalculadoraDto } from '../models'
// @ts-ignore
import { CreateIntegracoeDto } from '../models'
// @ts-ignore
import { FinanceiroCompetenciaParcelas } from '../models'
// @ts-ignore
import { FinanceiroParcelaListEntity } from '../models'
// @ts-ignore
import { FindOneIntegracoeDto } from '../models'
// @ts-ignore
import { ForgotPassword } from '../models'
// @ts-ignore
import { GetCalcularFloatDto } from '../models'
// @ts-ignore
import { GetDocumentoAssinado } from '../models'
// @ts-ignore
import { GetIndicadoresProposta } from '../models'
// @ts-ignore
import { GetRelatorioCalculadoraDto } from '../models'
// @ts-ignore
import { GetcalcularParcelasSegurado } from '../models'
// @ts-ignore
import { LoginForm } from '../models'
// @ts-ignore
import { NegarProposta } from '../models'
// @ts-ignore
import { Pesquisar } from '../models'
// @ts-ignore
import { PesquisarRelatorioFinanceiroDto } from '../models'
// @ts-ignore
import { RecoverPassword } from '../models'
// @ts-ignore
import { RetornoExportacaoMargemDto } from '../models'
// @ts-ignore
import { UpdateIntegracoeDto } from '../models'
/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete', 'id', id)
      const localVarPath = `/cadastro/orgao/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete_1: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete_1', 'id', id)
      const localVarPath = `/cadastro/grupoFolha/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete_2: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('_delete_2', 'id', id)
      const localVarPath = `/cadastro/documentoModelo/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alterarStatusRecalculo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/lancamento/alterarStatusRecalculo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {AprovarProposta} aprovarProposta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    aprovarProposta: async (aprovarProposta: AprovarProposta, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'aprovarProposta' is not null or undefined
      assertParamExists('aprovarProposta', 'aprovarProposta', aprovarProposta)
      const localVarPath = `/proposta/aprovarProposta`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(aprovarProposta, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {AssinaDocumentoDto} assinaDocumentoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assinaDocumento: async (
      assinaDocumentoDto: AssinaDocumentoDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'assinaDocumentoDto' is not null or undefined
      assertParamExists('assinaDocumento', 'assinaDocumentoDto', assinaDocumentoDto)
      const localVarPath = `/assinatura-documentos/AssinaDocumento`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(assinaDocumentoDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} cpf
     * @param {number} matricula
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    atualizaMargemSegurado: async (
      cpf: string,
      matricula: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'cpf' is not null or undefined
      assertParamExists('atualizaMargemSegurado', 'cpf', cpf)
      // verify required parameter 'matricula' is not null or undefined
      assertParamExists('atualizaMargemSegurado', 'matricula', matricula)
      const localVarPath = `/utils/atualizaMargemSegurado/{cpf}/{matricula}`
        .replace(`{${'cpf'}}`, encodeURIComponent(String(cpf)))
        .replace(`{${'matricula'}}`, encodeURIComponent(String(matricula)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    atualizaStatusRenegociacao: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/AtualizaStatusRenegociacao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    atualizaVencimentoRenegociacao: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/AtualizaVencimentoRenegociacao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} userId
     * @param {string} financeiroId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    atualizar: async (userId: string, financeiroId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('atualizar', 'userId', userId)
      // verify required parameter 'financeiroId' is not null or undefined
      assertParamExists('atualizar', 'financeiroId', financeiroId)
      const localVarPath = `/financeiro-assinatura/{financeiroId}/{userId}`
        .replace(`{${'userId'}}`, encodeURIComponent(String(userId)))
        .replace(`{${'financeiroId'}}`, encodeURIComponent(String(financeiroId)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    baixaLancamentosManualmente: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/lancamento/BaixaLancamentosManualmente`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bancos: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/utils/bancos`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buscaPorInstitutoId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('buscaPorInstitutoId', 'id', id)
      const localVarPath = `/cadastro/orgao/buscaPorInstitutoId/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buscaPorInstitutoId_3: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('buscaPorInstitutoId_3', 'id', id)
      const localVarPath = `/cadastro/grupoFolha/buscaPorInstitutoId/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buscarNotificacoes: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('buscarNotificacoes', 'id', id)
      const localVarPath = `/utils/buscarNotificacoes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cadastraAntecipacao: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/CadastraAntecipacao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CalcularProposta} calcularProposta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calcularProposta: async (calcularProposta: CalcularProposta, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'calcularProposta' is not null or undefined
      assertParamExists('calcularProposta', 'calcularProposta', calcularProposta)
      const localVarPath = `/proposta/calcularProposta`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(calcularProposta, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    carregarDadosParcelas: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/CarregarDadosParcelas/{id}`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    carregarParcelas: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('carregarParcelas', 'id', id)
      const localVarPath = `/proposta/carregarParcelas/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    carregarParcelas_4: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('carregarParcelas_4', 'id', id)
      const localVarPath = `/simulacao/carregarParcelas/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cidades: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('cidades', 'id', id)
      const localVarPath = `/utils/cidades/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmarPagamentoExonenacoesRegularizadas: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/ConfirmarPagamentoExonenacoesRegularizadas`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmarPagamentoObitosRegularizados: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/ConfirmarPagamentoObitosRegularizados`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmarPagamentoParcelasVencidasRegularizadas: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/ConfirmarPagamentoParcelasVencidasRegularizadas`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('create', 'body', body)
      const localVarPath = `/boleto-prisma`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {CreateIntegracoeDto} createIntegracoeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create_5: async (
      id: number,
      createIntegracoeDto: CreateIntegracoeDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('create_5', 'id', id)
      // verify required parameter 'createIntegracoeDto' is not null or undefined
      assertParamExists('create_5', 'createIntegracoeDto', createIntegracoeDto)
      const localVarPath = `/integracoes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(createIntegracoeDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    criar: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/financeiro-assinatura`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNotificacoes: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deleteNotificacoes', 'id', id)
      const localVarPath = `/utils/deleteNotificacoes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    download: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('download', 'id', id)
      const localVarPath = `/cadastro/documentoModelo/download/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {FindOneIntegracoeDto} findOneIntegracoeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findOne: async (
      id: string,
      findOneIntegracoeDto: FindOneIntegracoeDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('findOne', 'id', id)
      // verify required parameter 'findOneIntegracoeDto' is not null or undefined
      assertParamExists('findOne', 'findOneIntegracoeDto', findOneIntegracoeDto)
      const localVarPath = `/integracoes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(findOneIntegracoeDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerar: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('gerar', 'body', body)
      const localVarPath = `/boleto-remessa`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioAmortizacao: async (
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pesquisarRelatorioFinanceiroDto' is not null or undefined
      assertParamExists('gerarRelatorioAmortizacao', 'pesquisarRelatorioFinanceiroDto', pesquisarRelatorioFinanceiroDto)
      const localVarPath = `/relatorio/amortizacao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        pesquisarRelatorioFinanceiroDto,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioFundoGarantido: async (
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pesquisarRelatorioFinanceiroDto' is not null or undefined
      assertParamExists('gerarRelatorioFundoGarantido', 'pesquisarRelatorioFinanceiroDto', pesquisarRelatorioFinanceiroDto)
      const localVarPath = `/relatorio/fundoGarantido`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        pesquisarRelatorioFinanceiroDto,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioOscilacaoDeRiscos: async (
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pesquisarRelatorioFinanceiroDto' is not null or undefined
      assertParamExists('gerarRelatorioOscilacaoDeRiscos', 'pesquisarRelatorioFinanceiroDto', pesquisarRelatorioFinanceiroDto)
      const localVarPath = `/relatorio/oscilacaoDeRiscos`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        pesquisarRelatorioFinanceiroDto,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioParcelasConsolidadas: async (
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pesquisarRelatorioFinanceiroDto' is not null or undefined
      assertParamExists(
        'gerarRelatorioParcelasConsolidadas',
        'pesquisarRelatorioFinanceiroDto',
        pesquisarRelatorioFinanceiroDto,
      )
      const localVarPath = `/relatorio/parcelasConsolidadas`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        pesquisarRelatorioFinanceiroDto,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioParcelasRecebidasPorCompetencia: async (
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pesquisarRelatorioFinanceiroDto' is not null or undefined
      assertParamExists(
        'gerarRelatorioParcelasRecebidasPorCompetencia',
        'pesquisarRelatorioFinanceiroDto',
        pesquisarRelatorioFinanceiroDto,
      )
      const localVarPath = `/relatorio/parcelasRecebidasPorCompetencia`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        pesquisarRelatorioFinanceiroDto,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioRentabilidadeConsolidado: async (
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pesquisarRelatorioFinanceiroDto' is not null or undefined
      assertParamExists(
        'gerarRelatorioRentabilidadeConsolidado',
        'pesquisarRelatorioFinanceiroDto',
        pesquisarRelatorioFinanceiroDto,
      )
      const localVarPath = `/relatorio/rentabilidadeConsolidado`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        pesquisarRelatorioFinanceiroDto,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioRentabilidadePorCompetencia: async (
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pesquisarRelatorioFinanceiroDto' is not null or undefined
      assertParamExists(
        'gerarRelatorioRentabilidadePorCompetencia',
        'pesquisarRelatorioFinanceiroDto',
        pesquisarRelatorioFinanceiroDto,
      )
      const localVarPath = `/relatorio/rentabilidadePorCompetencia`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        pesquisarRelatorioFinanceiroDto,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioTaxaAdministracaoConsolidado: async (
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pesquisarRelatorioFinanceiroDto' is not null or undefined
      assertParamExists(
        'gerarRelatorioTaxaAdministracaoConsolidado',
        'pesquisarRelatorioFinanceiroDto',
        pesquisarRelatorioFinanceiroDto,
      )
      const localVarPath = `/relatorio/taxaAdministracaoConsolidado`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        pesquisarRelatorioFinanceiroDto,
        localVarRequestOptions,
        configuration,
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRenegociacao: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('gerarRenegociacao', 'pesquisar', pesquisar)
      const localVarPath = `/renegociacao/gerarRenegociacao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCadastro: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getAllCadastro', 'pesquisar', pesquisar)
      const localVarPath = `/relatorio/GetAllCadastro`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnalisesAdm: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getAnalisesAdm', 'pesquisar', pesquisar)
      const localVarPath = `/utils/GetAnalisesAdm`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnalisesEmprestimo: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getAnalisesEmprestimo', 'pesquisar', pesquisar)
      const localVarPath = `/utils/GetAnalisesEmprestimo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnalisesPropostas: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getAnalisesPropostas', 'pesquisar', pesquisar)
      const localVarPath = `/utils/GetAnalisesPropostas`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBancos: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getBancos', 'id', id)
      const localVarPath = `/utils/getBancos/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBoleto: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('getBoleto', 'body', body)
      const localVarPath = `/boleto-prisma/GetBoleto`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetCalcularFloatDto} getCalcularFloatDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCalcularFloat: async (
      getCalcularFloatDto: GetCalcularFloatDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getCalcularFloatDto' is not null or undefined
      assertParamExists('getCalcularFloat', 'getCalcularFloatDto', getCalcularFloatDto)
      const localVarPath = `/proposta/GetCalcularFloat`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(getCalcularFloatDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCalcularIOF: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/proposta/GetCalcularIOF`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCalcularParcelasAAntecipar: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/GetCalcularParcelasAAntecipar`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCarregarDadosParcelasAntecipacao: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/GetCarregarDadosParcelasAntecipacao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} cep
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCep: async (cep: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'cep' is not null or undefined
      assertParamExists('getCep', 'cep', cep)
      const localVarPath = `/utils/cep/{cep}`.replace(`{${'cep'}}`, encodeURIComponent(String(cep)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContratosComParcelasEmDivida: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getContratosComParcelasEmDivida', 'id', id)
      const localVarPath = `/renegociacao/GetContratosComParcelasEmDivida/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContratosComParcelasVencidas: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/GetContratosComParcelasVencidas`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContratosParaAntecipacao: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/GetContratosParaAntecipacao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCriteriosEligibilidade: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/proposta/GetCriteriosEligibilidade/{id}`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetDocumentoAssinado} getDocumentoAssinado
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentoAssinado: async (
      getDocumentoAssinado: GetDocumentoAssinado,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getDocumentoAssinado' is not null or undefined
      assertParamExists('getDocumentoAssinado', 'getDocumentoAssinado', getDocumentoAssinado)
      const localVarPath = `/assinatura-documentos/GetDocumentoAssinado`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(getDocumentoAssinado, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGerarRelatorioPDF: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/relatorio/GetGerarRelatorioPDF`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHello: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getId', 'id', id)
      const localVarPath = `/cadastro/orgao/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getId_6: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getId_6', 'id', id)
      const localVarPath = `/cadastro/grupoFolha/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getId_7: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getId_7', 'id', id)
      const localVarPath = `/cadastro/documentoModelo/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetIndicadoresProposta} getIndicadoresProposta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIndicadoresProposta: async (
      getIndicadoresProposta: GetIndicadoresProposta,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getIndicadoresProposta' is not null or undefined
      assertParamExists('getIndicadoresProposta', 'getIndicadoresProposta', getIndicadoresProposta)
      const localVarPath = `/proposta/GetIndicadoresProposta`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(getIndicadoresProposta, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLog: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getLog', 'pesquisar', pesquisar)
      const localVarPath = `/utils/GetLog`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificacoes: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getNotificacoes', 'pesquisar', pesquisar)
      const localVarPath = `/utils/GetNotificacoes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParametros: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/GetParametros`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} sigla
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParametrosCalculadora: async (sigla: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'sigla' is not null or undefined
      assertParamExists('getParametrosCalculadora', 'sigla', sigla)
      const localVarPath = `/calculadoraEmprestimo/GetParametrosCalculadora/{sigla}`.replace(
        `{${'sigla'}}`,
        encodeURIComponent(String(sigla)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParametrosRenegociacao: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getParametrosRenegociacao', 'id', id)
      const localVarPath = `/renegociacao/GetParametrosRenegociacao/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParcelasSeguradoRenegociacao: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/GetParcelasSeguradoRenegociacao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisar: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getPesquisar', 'pesquisar', pesquisar)
      const localVarPath = `/cadastro/orgao/GetPesquisar`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarAntecipacoes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/GetPesquisarAntecipacoes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarExoneradosPendentes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/GetPesquisarExoneradosPendentes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarExoneradosRegularizados: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/GetPesquisarExoneradosRegularizados`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarLancamentos: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getPesquisarLancamentos', 'pesquisar', pesquisar)
      const localVarPath = `/lancamento/GetPesquisarLancamentos`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarObitosPendentes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/GetPesquisarObitosPendentes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarObitosRegularizados: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/GetPesquisarObitosRegularizados`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarPorOrgao: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/grupoFolha/GetPesquisarPorOrgao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarVencidosPendentes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/GetPesquisarVencidosPendentes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisar_8: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getPesquisar_8', 'pesquisar', pesquisar)
      const localVarPath = `/cadastro/grupoFolha/GetPesquisar`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisar_9: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/GetPesquisar`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelatorio: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getRelatorio', 'pesquisar', pesquisar)
      const localVarPath = `/cadastro/orgao/GetRelatorio`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelatorioLanc: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getRelatorioLanc', 'pesquisar', pesquisar)
      const localVarPath = `/lancamento/GetRelatorioLanc`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelatorio_10: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/proposta/GetRelatorio`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelatorio_11: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getRelatorio_11', 'pesquisar', pesquisar)
      const localVarPath = `/cadastro/grupoFolha/GetRelatorio`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSimulacaoRenegociacaoSegurados: async (
      pesquisar: Pesquisar,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getSimulacaoRenegociacaoSegurados', 'pesquisar', pesquisar)
      const localVarPath = `/renegociacao/GetSimulacaoRenegociacaoSegurados`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} sigla
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnidadeGestora: async (sigla: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'sigla' is not null or undefined
      assertParamExists('getUnidadeGestora', 'sigla', sigla)
      const localVarPath = `/calculadoraEmprestimo/GetUnidadeGestora/{sigla}`.replace(
        `{${'sigla'}}`,
        encodeURIComponent(String(sigla)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetcalcularParcelasSegurado} getcalcularParcelasSegurado
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getcalcularParcelasSegurado: async (
      getcalcularParcelasSegurado: GetcalcularParcelasSegurado,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getcalcularParcelasSegurado' is not null or undefined
      assertParamExists('getcalcularParcelasSegurado', 'getcalcularParcelasSegurado', getcalcularParcelasSegurado)
      const localVarPath = `/utils/GetcalcularParcelasSegurado`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(getcalcularParcelasSegurado, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getcalcularValoresSegurado: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/utils/GetcalcularValoresSegurado`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getdocumentoModeloCheckList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/documentoModelo/GetdocumentoModeloCheckList`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getfaq: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getfaq', 'pesquisar', pesquisar)
      const localVarPath = `/utils/Getfaq`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ForgotPassword} forgotPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getforgotPassword: async (forgotPassword: ForgotPassword, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'forgotPassword' is not null or undefined
      assertParamExists('getforgotPassword', 'forgotPassword', forgotPassword)
      const localVarPath = `/auth/GetforgotPassword`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(forgotPassword, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {LoginForm} loginForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getlogin: async (loginForm: LoginForm, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'loginForm' is not null or undefined
      assertParamExists('getlogin', 'loginForm', loginForm)
      const localVarPath = `/auth/Getlogin`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(loginForm, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getpesquisar: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/proposta/Getpesquisar`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getpesquisar_12: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getpesquisar_12', 'pesquisar', pesquisar)
      const localVarPath = `/simulacao/Getpesquisar`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getpesquisar_13: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getpesquisar_13', 'pesquisar', pesquisar)
      const localVarPath = `/cadastro/documentoModelo/Getpesquisar`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getpropostasTomador: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/proposta/GetpropostasTomador`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getrelatorio: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'pesquisar' is not null or undefined
      assertParamExists('getrelatorio', 'pesquisar', pesquisar)
      const localVarPath = `/simulacao/Getrelatorio`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {GetRelatorioCalculadoraDto} getRelatorioCalculadoraDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getrelatorio_14: async (
      getRelatorioCalculadoraDto: GetRelatorioCalculadoraDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'getRelatorioCalculadoraDto' is not null or undefined
      assertParamExists('getrelatorio_14', 'getRelatorioCalculadoraDto', getRelatorioCalculadoraDto)
      const localVarPath = `/calculadoraEmprestimo/Getrelatorio`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(getRelatorioCalculadoraDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsimulacoesTomador: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/simulacao/GetsimulacoesTomador`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {LoginForm} loginForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getverifyPassword: async (loginForm: LoginForm, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'loginForm' is not null or undefined
      assertParamExists('getverifyPassword', 'loginForm', loginForm)
      const localVarPath = `/auth/GetverifyPassword`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(loginForm, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    grupoFolha: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/grupoFolha`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importar: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/pagamento/upload`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'body' is not null or undefined
      assertParamExists('list', 'body', body)
      const localVarPath = `/boleto-prisma/list`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listParcelas: async (
      financeiroParcelaListEntity: FinanceiroParcelaListEntity,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'financeiroParcelaListEntity' is not null or undefined
      assertParamExists('listParcelas', 'financeiroParcelaListEntity', financeiroParcelaListEntity)
      const localVarPath = `/lancamento/list`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(financeiroParcelaListEntity, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listParcelasACalcular: async (
      financeiroParcelaListEntity: FinanceiroParcelaListEntity,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'financeiroParcelaListEntity' is not null or undefined
      assertParamExists('listParcelasACalcular', 'financeiroParcelaListEntity', financeiroParcelaListEntity)
      const localVarPath = `/lancamento/listParaRecalculo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(financeiroParcelaListEntity, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listParcelasARegularizar: async (
      financeiroParcelaListEntity: FinanceiroParcelaListEntity,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'financeiroParcelaListEntity' is not null or undefined
      assertParamExists('listParcelasARegularizar', 'financeiroParcelaListEntity', financeiroParcelaListEntity)
      const localVarPath = `/lancamento/listARegularizar`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(financeiroParcelaListEntity, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listParcelasArrecadacao: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('listParcelasArrecadacao', 'id', id)
      const localVarPath = `/lancamento/getListArrecadacao/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listParcelasNaoDescontadas: async (
      financeiroParcelaListEntity: FinanceiroParcelaListEntity,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'financeiroParcelaListEntity' is not null or undefined
      assertParamExists('listParcelasNaoDescontadas', 'financeiroParcelaListEntity', financeiroParcelaListEntity)
      const localVarPath = `/lancamento/listNaoDescontado`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(financeiroParcelaListEntity, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} userId
     * @param {string} tomador
     * @param {string} testemunha
     * @param {string} gestor
     * @param {string} page
     * @param {string} perPage
     * @param {number} institutoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listar: async (
      userId: string,
      tomador: string,
      testemunha: string,
      gestor: string,
      page: string,
      perPage: string,
      institutoId: number,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'userId' is not null or undefined
      assertParamExists('listar', 'userId', userId)
      // verify required parameter 'tomador' is not null or undefined
      assertParamExists('listar', 'tomador', tomador)
      // verify required parameter 'testemunha' is not null or undefined
      assertParamExists('listar', 'testemunha', testemunha)
      // verify required parameter 'gestor' is not null or undefined
      assertParamExists('listar', 'gestor', gestor)
      // verify required parameter 'page' is not null or undefined
      assertParamExists('listar', 'page', page)
      // verify required parameter 'perPage' is not null or undefined
      assertParamExists('listar', 'perPage', perPage)
      // verify required parameter 'institutoId' is not null or undefined
      assertParamExists('listar', 'institutoId', institutoId)
      const localVarPath = `/financeiro-assinatura`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (userId !== undefined) {
        localVarQueryParameter['userId'] = userId
      }

      if (tomador !== undefined) {
        localVarQueryParameter['tomador'] = tomador
      }

      if (testemunha !== undefined) {
        localVarQueryParameter['testemunha'] = testemunha
      }

      if (gestor !== undefined) {
        localVarQueryParameter['gestor'] = gestor
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page
      }

      if (perPage !== undefined) {
        localVarQueryParameter['perPage'] = perPage
      }

      if (institutoId !== undefined) {
        localVarQueryParameter['institutoId'] = institutoId
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listarUnidadeGestora: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/calculadoraEmprestimo/GetListaUnidadeGestora`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {NegarProposta} negarProposta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    negarProposta: async (negarProposta: NegarProposta, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'negarProposta' is not null or undefined
      assertParamExists('negarProposta', 'negarProposta', negarProposta)
      const localVarPath = `/proposta/negarProposta`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(negarProposta, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ConsultaCalculadoraDto} consultaCalculadoraDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novaConsultaCalculadora: async (
      consultaCalculadoraDto: ConsultaCalculadoraDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'consultaCalculadoraDto' is not null or undefined
      assertParamExists('novaConsultaCalculadora', 'consultaCalculadoraDto', consultaCalculadoraDto)
      const localVarPath = `/calculadoraEmprestimo/NovaConsultaCalculadora`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(consultaCalculadoraDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novaProposta: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/proposta/novaProposta`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novaRenegociacaoExonerados: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/NovaRenegociacaoExonerados`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novaRenegociacaoFalecidos: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/NovaRenegociacaoFalecidos`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novaSimulacao: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/simulacao/novaSimulacao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novoAcordo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/renegociacao/NovoAcordo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orgao: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/orgao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paises: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/utils/paises`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/orgao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLog: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNotificacoes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/utils/postNotificacoes`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNotificao: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/utils/postNotificao`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post_15: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/grupoFolha`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('put', 'id', id)
      const localVarPath = `/cadastro/orgao/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putContratoGerado: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/proposta/PutContratoGerado`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putLancamento: async (
      financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'financeiroCompetenciaParcelas' is not null or undefined
      assertParamExists('putLancamento', 'financeiroCompetenciaParcelas', financeiroCompetenciaParcelas)
      const localVarPath = `/lancamento/putLancamento`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(financeiroCompetenciaParcelas, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put_16: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('put_16', 'id', id)
      const localVarPath = `/cadastro/grupoFolha/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    RealizaEnvioDescontoParaSisprev: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/lancamento/RealizaEnvioDescontoParaSisprev`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recalcularListaDeParcelas: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/lancamento/recalcularListaDeParcelas`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    receber: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/financeiro-assinatura/receber`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {RecoverPassword} recoverPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recoverPassword: async (recoverPassword: RecoverPassword, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'recoverPassword' is not null or undefined
      assertParamExists('recoverPassword', 'recoverPassword', recoverPassword)
      const localVarPath = `/auth/recoverPassword`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(recoverPassword, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {RetornoExportacaoMargemDto} retornoExportacaoMargemDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retornoExportacaoMargem: async (
      retornoExportacaoMargemDto: RetornoExportacaoMargemDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'retornoExportacaoMargemDto' is not null or undefined
      assertParamExists('retornoExportacaoMargem', 'retornoExportacaoMargemDto', retornoExportacaoMargemDto)
      const localVarPath = `/lancamento/RetornoExportacaoMargem`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(retornoExportacaoMargemDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    solicitar: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/financeiro-assinatura/solicitar`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ultimaParcelaData: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/relatorio/ultima-parcela`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unPutLancamento: async (
      financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'financeiroCompetenciaParcelas' is not null or undefined
      assertParamExists('unPutLancamento', 'financeiroCompetenciaParcelas', financeiroCompetenciaParcelas)
      const localVarPath = `/lancamento/unPutLancamento`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(financeiroCompetenciaParcelas, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {UpdateIntegracoeDto} updateIntegracoeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update: async (
      id: number,
      updateIntegracoeDto: UpdateIntegracoeDto,
      options: RawAxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('update', 'id', id)
      // verify required parameter 'updateIntegracoeDto' is not null or undefined
      assertParamExists('update', 'updateIntegracoeDto', updateIntegracoeDto)
      const localVarPath = `/integracoes/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter['Content-Type'] = 'application/json'

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(updateIntegracoeDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadedFile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cadastro/documentoModelo`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication bearer required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifica: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('verifica', 'id', id)
      const localVarPath = `/financeiro-assinatura/verifica/assinatura/{id}`.replace(
        `{${'id'}}`,
        encodeURIComponent(String(id)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} cpf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verificaSeExisteTomador: async (cpf: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'cpf' is not null or undefined
      assertParamExists('verificaSeExisteTomador', 'cpf', cpf)
      const localVarPath = `/calculadoraEmprestimo/verificaSeExisteTomador/{cpf}`.replace(
        `{${'cpf'}}`,
        encodeURIComponent(String(cpf)),
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi._delete']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete_1(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete_1(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi._delete_1']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async _delete_2(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator._delete_2(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi._delete_2']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async alterarStatusRecalculo(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.alterarStatusRecalculo(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.alterarStatusRecalculo']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {AprovarProposta} aprovarProposta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async aprovarProposta(
      aprovarProposta: AprovarProposta,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.aprovarProposta(aprovarProposta, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.aprovarProposta']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {AssinaDocumentoDto} assinaDocumentoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assinaDocumento(
      assinaDocumentoDto: AssinaDocumentoDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assinaDocumento(assinaDocumentoDto, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.assinaDocumento']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {string} cpf
     * @param {number} matricula
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async atualizaMargemSegurado(
      cpf: string,
      matricula: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.atualizaMargemSegurado(cpf, matricula, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.atualizaMargemSegurado']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async atualizaStatusRenegociacao(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.atualizaStatusRenegociacao(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.atualizaStatusRenegociacao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async atualizaVencimentoRenegociacao(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.atualizaVencimentoRenegociacao(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.atualizaVencimentoRenegociacao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {string} userId
     * @param {string} financeiroId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async atualizar(
      userId: string,
      financeiroId: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.atualizar(userId, financeiroId, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.atualizar']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async baixaLancamentosManualmente(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.baixaLancamentosManualmente(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.baixaLancamentosManualmente']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bancos(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bancos(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.bancos']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async buscaPorInstitutoId(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.buscaPorInstitutoId(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.buscaPorInstitutoId']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async buscaPorInstitutoId_3(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.buscaPorInstitutoId_3(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.buscaPorInstitutoId_3']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async buscarNotificacoes(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.buscarNotificacoes(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.buscarNotificacoes']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cadastraAntecipacao(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cadastraAntecipacao(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.cadastraAntecipacao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {CalcularProposta} calcularProposta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async calcularProposta(
      calcularProposta: CalcularProposta,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.calcularProposta(calcularProposta, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.calcularProposta']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async carregarDadosParcelas(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.carregarDadosParcelas(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.carregarDadosParcelas']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async carregarParcelas(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.carregarParcelas(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.carregarParcelas']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async carregarParcelas_4(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.carregarParcelas_4(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.carregarParcelas_4']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cidades(
      id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cidades(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.cidades']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async confirmarPagamentoExonenacoesRegularizadas(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.confirmarPagamentoExonenacoesRegularizadas(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.confirmarPagamentoExonenacoesRegularizadas']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async confirmarPagamentoObitosRegularizados(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.confirmarPagamentoObitosRegularizados(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.confirmarPagamentoObitosRegularizados']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async confirmarPagamentoParcelasVencidasRegularizadas(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.confirmarPagamentoParcelasVencidasRegularizadas(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.confirmarPagamentoParcelasVencidasRegularizadas']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create(
      body: object,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create(body, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.create']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {CreateIntegracoeDto} createIntegracoeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async create_5(
      id: number,
      createIntegracoeDto: CreateIntegracoeDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.create_5(id, createIntegracoeDto, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.create_5']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async criar(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.criar(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.criar']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteNotificacoes(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNotificacoes(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.deleteNotificacoes']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async download(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.download(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.download']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {FindOneIntegracoeDto} findOneIntegracoeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async findOne(
      id: string,
      findOneIntegracoeDto: FindOneIntegracoeDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.findOne(id, findOneIntegracoeDto, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.findOne']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gerar(
      body: object,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gerar(body, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.gerar']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gerarRelatorioAmortizacao(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gerarRelatorioAmortizacao(
        pesquisarRelatorioFinanceiroDto,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.gerarRelatorioAmortizacao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gerarRelatorioFundoGarantido(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gerarRelatorioFundoGarantido(
        pesquisarRelatorioFinanceiroDto,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.gerarRelatorioFundoGarantido']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gerarRelatorioOscilacaoDeRiscos(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gerarRelatorioOscilacaoDeRiscos(
        pesquisarRelatorioFinanceiroDto,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.gerarRelatorioOscilacaoDeRiscos']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gerarRelatorioParcelasConsolidadas(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gerarRelatorioParcelasConsolidadas(
        pesquisarRelatorioFinanceiroDto,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.gerarRelatorioParcelasConsolidadas']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gerarRelatorioParcelasRecebidasPorCompetencia(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gerarRelatorioParcelasRecebidasPorCompetencia(
        pesquisarRelatorioFinanceiroDto,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.gerarRelatorioParcelasRecebidasPorCompetencia']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gerarRelatorioRentabilidadeConsolidado(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gerarRelatorioRentabilidadeConsolidado(
        pesquisarRelatorioFinanceiroDto,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.gerarRelatorioRentabilidadeConsolidado']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gerarRelatorioRentabilidadePorCompetencia(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gerarRelatorioRentabilidadePorCompetencia(
        pesquisarRelatorioFinanceiroDto,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.gerarRelatorioRentabilidadePorCompetencia']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gerarRelatorioTaxaAdministracaoConsolidado(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gerarRelatorioTaxaAdministracaoConsolidado(
        pesquisarRelatorioFinanceiroDto,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.gerarRelatorioTaxaAdministracaoConsolidado']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async gerarRenegociacao(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.gerarRenegociacao(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.gerarRenegociacao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllCadastro(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCadastro(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getAllCadastro']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnalisesAdm(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalisesAdm(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getAnalisesAdm']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnalisesEmprestimo(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalisesEmprestimo(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getAnalisesEmprestimo']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnalisesPropostas(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalisesPropostas(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getAnalisesPropostas']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBancos(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBancos(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getBancos']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBoleto(
      body: object,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBoleto(body, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getBoleto']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {GetCalcularFloatDto} getCalcularFloatDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCalcularFloat(
      getCalcularFloatDto: GetCalcularFloatDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCalcularFloat(getCalcularFloatDto, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getCalcularFloat']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCalcularIOF(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCalcularIOF(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getCalcularIOF']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCalcularParcelasAAntecipar(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCalcularParcelasAAntecipar(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getCalcularParcelasAAntecipar']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCarregarDadosParcelasAntecipacao(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCarregarDadosParcelasAntecipacao(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getCarregarDadosParcelasAntecipacao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {string} cep
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCep(
      cep: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCep(cep, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getCep']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContratosComParcelasEmDivida(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContratosComParcelasEmDivida(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getContratosComParcelasEmDivida']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContratosComParcelasVencidas(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContratosComParcelasVencidas(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getContratosComParcelasVencidas']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getContratosParaAntecipacao(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getContratosParaAntecipacao(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getContratosParaAntecipacao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCriteriosEligibilidade(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCriteriosEligibilidade(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getCriteriosEligibilidade']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {GetDocumentoAssinado} getDocumentoAssinado
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDocumentoAssinado(
      getDocumentoAssinado: GetDocumentoAssinado,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentoAssinado(getDocumentoAssinado, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getDocumentoAssinado']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getGerarRelatorioPDF(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getGerarRelatorioPDF(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getGerarRelatorioPDF']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHello(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHello(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getHello']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getId(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getId(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getId']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getId_6(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getId_6(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getId_6']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getId_7(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getId_7(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getId_7']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {GetIndicadoresProposta} getIndicadoresProposta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getIndicadoresProposta(
      getIndicadoresProposta: GetIndicadoresProposta,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getIndicadoresProposta(getIndicadoresProposta, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getIndicadoresProposta']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLog(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLog(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getLog']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNotificacoes(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificacoes(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getNotificacoes']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getParametros(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getParametros(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getParametros']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {string} sigla
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getParametrosCalculadora(
      sigla: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getParametrosCalculadora(sigla, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getParametrosCalculadora']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getParametrosRenegociacao(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getParametrosRenegociacao(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getParametrosRenegociacao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getParcelasSeguradoRenegociacao(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getParcelasSeguradoRenegociacao(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getParcelasSeguradoRenegociacao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPesquisar(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPesquisar(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getPesquisar']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPesquisarAntecipacoes(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPesquisarAntecipacoes(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getPesquisarAntecipacoes']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPesquisarExoneradosPendentes(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPesquisarExoneradosPendentes(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getPesquisarExoneradosPendentes']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPesquisarExoneradosRegularizados(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPesquisarExoneradosRegularizados(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getPesquisarExoneradosRegularizados']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPesquisarLancamentos(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPesquisarLancamentos(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getPesquisarLancamentos']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPesquisarObitosPendentes(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPesquisarObitosPendentes(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getPesquisarObitosPendentes']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPesquisarObitosRegularizados(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPesquisarObitosRegularizados(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getPesquisarObitosRegularizados']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPesquisarPorOrgao(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPesquisarPorOrgao(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getPesquisarPorOrgao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPesquisarVencidosPendentes(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPesquisarVencidosPendentes(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getPesquisarVencidosPendentes']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPesquisar_8(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPesquisar_8(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getPesquisar_8']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPesquisar_9(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPesquisar_9(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getPesquisar_9']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRelatorio(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatorio(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getRelatorio']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRelatorioLanc(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatorioLanc(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getRelatorioLanc']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRelatorio_10(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatorio_10(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getRelatorio_10']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRelatorio_11(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatorio_11(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getRelatorio_11']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSimulacaoRenegociacaoSegurados(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSimulacaoRenegociacaoSegurados(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getSimulacaoRenegociacaoSegurados']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {string} sigla
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUnidadeGestora(
      sigla: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUnidadeGestora(sigla, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getUnidadeGestora']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {GetcalcularParcelasSegurado} getcalcularParcelasSegurado
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getcalcularParcelasSegurado(
      getcalcularParcelasSegurado: GetcalcularParcelasSegurado,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getcalcularParcelasSegurado(
        getcalcularParcelasSegurado,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getcalcularParcelasSegurado']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getcalcularValoresSegurado(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getcalcularValoresSegurado(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getcalcularValoresSegurado']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getdocumentoModeloCheckList(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getdocumentoModeloCheckList(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getdocumentoModeloCheckList']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getfaq(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getfaq(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getfaq']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {ForgotPassword} forgotPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getforgotPassword(
      forgotPassword: ForgotPassword,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getforgotPassword(forgotPassword, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getforgotPassword']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {LoginForm} loginForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getlogin(
      loginForm: LoginForm,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getlogin(loginForm, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getlogin']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getpesquisar(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getpesquisar(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getpesquisar']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getpesquisar_12(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getpesquisar_12(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getpesquisar_12']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getpesquisar_13(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getpesquisar_13(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getpesquisar_13']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getpropostasTomador(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getpropostasTomador(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getpropostasTomador']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getrelatorio(
      pesquisar: Pesquisar,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getrelatorio(pesquisar, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getrelatorio']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {GetRelatorioCalculadoraDto} getRelatorioCalculadoraDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getrelatorio_14(
      getRelatorioCalculadoraDto: GetRelatorioCalculadoraDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getrelatorio_14(getRelatorioCalculadoraDto, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getrelatorio_14']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getsimulacoesTomador(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getsimulacoesTomador(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getsimulacoesTomador']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {LoginForm} loginForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getverifyPassword(
      loginForm: LoginForm,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getverifyPassword(loginForm, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.getverifyPassword']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async grupoFolha(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.grupoFolha(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.grupoFolha']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async importar(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.importar(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.importar']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async list(
      body: object,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.list(body, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.list']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listParcelas(
      financeiroParcelaListEntity: FinanceiroParcelaListEntity,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listParcelas(financeiroParcelaListEntity, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.listParcelas']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listParcelasACalcular(
      financeiroParcelaListEntity: FinanceiroParcelaListEntity,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listParcelasACalcular(financeiroParcelaListEntity, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.listParcelasACalcular']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listParcelasARegularizar(
      financeiroParcelaListEntity: FinanceiroParcelaListEntity,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listParcelasARegularizar(financeiroParcelaListEntity, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.listParcelasARegularizar']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listParcelasArrecadacao(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listParcelasArrecadacao(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.listParcelasArrecadacao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listParcelasNaoDescontadas(
      financeiroParcelaListEntity: FinanceiroParcelaListEntity,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listParcelasNaoDescontadas(financeiroParcelaListEntity, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.listParcelasNaoDescontadas']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {string} userId
     * @param {string} tomador
     * @param {string} testemunha
     * @param {string} gestor
     * @param {string} page
     * @param {string} perPage
     * @param {number} institutoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listar(
      userId: string,
      tomador: string,
      testemunha: string,
      gestor: string,
      page: string,
      perPage: string,
      institutoId: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listar(
        userId,
        tomador,
        testemunha,
        gestor,
        page,
        perPage,
        institutoId,
        options,
      )
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.listar']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listarUnidadeGestora(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listarUnidadeGestora(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.listarUnidadeGestora']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {NegarProposta} negarProposta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async negarProposta(
      negarProposta: NegarProposta,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.negarProposta(negarProposta, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.negarProposta']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {ConsultaCalculadoraDto} consultaCalculadoraDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async novaConsultaCalculadora(
      consultaCalculadoraDto: ConsultaCalculadoraDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.novaConsultaCalculadora(consultaCalculadoraDto, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.novaConsultaCalculadora']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async novaProposta(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.novaProposta(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.novaProposta']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async novaRenegociacaoExonerados(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.novaRenegociacaoExonerados(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.novaRenegociacaoExonerados']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async novaRenegociacaoFalecidos(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.novaRenegociacaoFalecidos(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.novaRenegociacaoFalecidos']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async novaSimulacao(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.novaSimulacao(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.novaSimulacao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async novoAcordo(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.novoAcordo(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.novoAcordo']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orgao(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.orgao(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.orgao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async paises(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.paises(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.paises']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async post(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.post(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.post']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postLog(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postLog(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.postLog']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNotificacoes(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postNotificacoes(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.postNotificacoes']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postNotificao(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postNotificao(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.postNotificao']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async post_15(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.post_15(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.post_15']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async put(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.put(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.put']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putContratoGerado(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putContratoGerado(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.putContratoGerado']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putLancamento(
      financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putLancamento(financeiroCompetenciaParcelas, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.putLancamento']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async put_16(
      id: number,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.put_16(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.put_16']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async RealizaEnvioDescontoParaSisprev(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.RealizaEnvioDescontoParaSisprev(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.RealizaEnvioDescontoParaSisprev']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recalcularListaDeParcelas(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recalcularListaDeParcelas(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.recalcularListaDeParcelas']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async receber(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.receber(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.receber']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {RecoverPassword} recoverPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async recoverPassword(
      recoverPassword: RecoverPassword,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.recoverPassword(recoverPassword, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.recoverPassword']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {RetornoExportacaoMargemDto} retornoExportacaoMargemDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retornoExportacaoMargem(
      retornoExportacaoMargemDto: RetornoExportacaoMargemDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.retornoExportacaoMargem(retornoExportacaoMargemDto, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.retornoExportacaoMargem']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async solicitar(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.solicitar(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.solicitar']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ultimaParcelaData(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ultimaParcelaData(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.ultimaParcelaData']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async unPutLancamento(
      financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.unPutLancamento(financeiroCompetenciaParcelas, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.unPutLancamento']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {number} id
     * @param {UpdateIntegracoeDto} updateIntegracoeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async update(
      id: number,
      updateIntegracoeDto: UpdateIntegracoeDto,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, updateIntegracoeDto, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.update']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadedFile(
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadedFile(options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.uploadedFile']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verifica(
      id: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verifica(id, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.verifica']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
    /**
     *
     * @param {string} cpf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async verificaSeExisteTomador(
      cpf: string,
      options?: RawAxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.verificaSeExisteTomador(cpf, options)
      const index = configuration?.serverIndex ?? 0
      const operationBasePath = operationServerMap['DefaultApi.verificaSeExisteTomador']?.[index]?.url
      return (axios, basePath) =>
        createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath)
    },
  }
}

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration)
  return {
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete(id: number, options?: any): AxiosPromise<void> {
      return localVarFp._delete(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete_1(id: number, options?: any): AxiosPromise<void> {
      return localVarFp._delete_1(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    _delete_2(id: number, options?: any): AxiosPromise<void> {
      return localVarFp._delete_2(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    alterarStatusRecalculo(options?: any): AxiosPromise<void> {
      return localVarFp.alterarStatusRecalculo(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {AprovarProposta} aprovarProposta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    aprovarProposta(aprovarProposta: AprovarProposta, options?: any): AxiosPromise<void> {
      return localVarFp.aprovarProposta(aprovarProposta, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {AssinaDocumentoDto} assinaDocumentoDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assinaDocumento(assinaDocumentoDto: AssinaDocumentoDto, options?: any): AxiosPromise<void> {
      return localVarFp.assinaDocumento(assinaDocumentoDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} cpf
     * @param {number} matricula
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    atualizaMargemSegurado(cpf: string, matricula: number, options?: any): AxiosPromise<void> {
      return localVarFp.atualizaMargemSegurado(cpf, matricula, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    atualizaStatusRenegociacao(options?: any): AxiosPromise<void> {
      return localVarFp.atualizaStatusRenegociacao(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    atualizaVencimentoRenegociacao(options?: any): AxiosPromise<void> {
      return localVarFp.atualizaVencimentoRenegociacao(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} userId
     * @param {string} financeiroId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    atualizar(userId: string, financeiroId: string, options?: any): AxiosPromise<void> {
      return localVarFp.atualizar(userId, financeiroId, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    baixaLancamentosManualmente(options?: any): AxiosPromise<void> {
      return localVarFp.baixaLancamentosManualmente(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bancos(options?: any): AxiosPromise<void> {
      return localVarFp.bancos(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buscaPorInstitutoId(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.buscaPorInstitutoId(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buscaPorInstitutoId_3(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.buscaPorInstitutoId_3(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    buscarNotificacoes(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.buscarNotificacoes(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cadastraAntecipacao(options?: any): AxiosPromise<void> {
      return localVarFp.cadastraAntecipacao(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CalcularProposta} calcularProposta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    calcularProposta(calcularProposta: CalcularProposta, options?: any): AxiosPromise<void> {
      return localVarFp.calcularProposta(calcularProposta, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    carregarDadosParcelas(options?: any): AxiosPromise<void> {
      return localVarFp.carregarDadosParcelas(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    carregarParcelas(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.carregarParcelas(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    carregarParcelas_4(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.carregarParcelas_4(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cidades(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.cidades(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmarPagamentoExonenacoesRegularizadas(options?: any): AxiosPromise<void> {
      return localVarFp.confirmarPagamentoExonenacoesRegularizadas(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmarPagamentoObitosRegularizados(options?: any): AxiosPromise<void> {
      return localVarFp.confirmarPagamentoObitosRegularizados(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    confirmarPagamentoParcelasVencidasRegularizadas(options?: any): AxiosPromise<void> {
      return localVarFp.confirmarPagamentoParcelasVencidasRegularizadas(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create(body: object, options?: any): AxiosPromise<void> {
      return localVarFp.create(body, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {CreateIntegracoeDto} createIntegracoeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    create_5(id: number, createIntegracoeDto: CreateIntegracoeDto, options?: any): AxiosPromise<void> {
      return localVarFp.create_5(id, createIntegracoeDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    criar(options?: any): AxiosPromise<void> {
      return localVarFp.criar(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteNotificacoes(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.deleteNotificacoes(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    download(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.download(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {FindOneIntegracoeDto} findOneIntegracoeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    findOne(id: string, findOneIntegracoeDto: FindOneIntegracoeDto, options?: any): AxiosPromise<void> {
      return localVarFp.findOne(id, findOneIntegracoeDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerar(body: object, options?: any): AxiosPromise<void> {
      return localVarFp.gerar(body, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioAmortizacao(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .gerarRelatorioAmortizacao(pesquisarRelatorioFinanceiroDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioFundoGarantido(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .gerarRelatorioFundoGarantido(pesquisarRelatorioFinanceiroDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioOscilacaoDeRiscos(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .gerarRelatorioOscilacaoDeRiscos(pesquisarRelatorioFinanceiroDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioParcelasConsolidadas(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .gerarRelatorioParcelasConsolidadas(pesquisarRelatorioFinanceiroDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioParcelasRecebidasPorCompetencia(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .gerarRelatorioParcelasRecebidasPorCompetencia(pesquisarRelatorioFinanceiroDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioRentabilidadeConsolidado(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .gerarRelatorioRentabilidadeConsolidado(pesquisarRelatorioFinanceiroDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioRentabilidadePorCompetencia(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .gerarRelatorioRentabilidadePorCompetencia(pesquisarRelatorioFinanceiroDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRelatorioTaxaAdministracaoConsolidado(
      pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .gerarRelatorioTaxaAdministracaoConsolidado(pesquisarRelatorioFinanceiroDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    gerarRenegociacao(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.gerarRenegociacao(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllCadastro(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getAllCadastro(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnalisesAdm(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getAnalisesAdm(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnalisesEmprestimo(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getAnalisesEmprestimo(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnalisesPropostas(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getAnalisesPropostas(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBancos(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.getBancos(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBoleto(body: object, options?: any): AxiosPromise<void> {
      return localVarFp.getBoleto(body, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetCalcularFloatDto} getCalcularFloatDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCalcularFloat(getCalcularFloatDto: GetCalcularFloatDto, options?: any): AxiosPromise<void> {
      return localVarFp.getCalcularFloat(getCalcularFloatDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCalcularIOF(options?: any): AxiosPromise<void> {
      return localVarFp.getCalcularIOF(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCalcularParcelasAAntecipar(options?: any): AxiosPromise<void> {
      return localVarFp.getCalcularParcelasAAntecipar(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCarregarDadosParcelasAntecipacao(options?: any): AxiosPromise<void> {
      return localVarFp.getCarregarDadosParcelasAntecipacao(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} cep
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCep(cep: string, options?: any): AxiosPromise<void> {
      return localVarFp.getCep(cep, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContratosComParcelasEmDivida(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.getContratosComParcelasEmDivida(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContratosComParcelasVencidas(options?: any): AxiosPromise<void> {
      return localVarFp.getContratosComParcelasVencidas(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getContratosParaAntecipacao(options?: any): AxiosPromise<void> {
      return localVarFp.getContratosParaAntecipacao(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCriteriosEligibilidade(options?: any): AxiosPromise<void> {
      return localVarFp.getCriteriosEligibilidade(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetDocumentoAssinado} getDocumentoAssinado
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDocumentoAssinado(getDocumentoAssinado: GetDocumentoAssinado, options?: any): AxiosPromise<void> {
      return localVarFp.getDocumentoAssinado(getDocumentoAssinado, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getGerarRelatorioPDF(options?: any): AxiosPromise<void> {
      return localVarFp.getGerarRelatorioPDF(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHello(options?: any): AxiosPromise<void> {
      return localVarFp.getHello(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getId(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.getId(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getId_6(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.getId_6(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getId_7(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.getId_7(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetIndicadoresProposta} getIndicadoresProposta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getIndicadoresProposta(getIndicadoresProposta: GetIndicadoresProposta, options?: any): AxiosPromise<void> {
      return localVarFp.getIndicadoresProposta(getIndicadoresProposta, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLog(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getLog(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNotificacoes(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getNotificacoes(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParametros(options?: any): AxiosPromise<void> {
      return localVarFp.getParametros(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} sigla
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParametrosCalculadora(sigla: string, options?: any): AxiosPromise<void> {
      return localVarFp.getParametrosCalculadora(sigla, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParametrosRenegociacao(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.getParametrosRenegociacao(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParcelasSeguradoRenegociacao(options?: any): AxiosPromise<void> {
      return localVarFp.getParcelasSeguradoRenegociacao(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisar(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getPesquisar(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarAntecipacoes(options?: any): AxiosPromise<void> {
      return localVarFp.getPesquisarAntecipacoes(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarExoneradosPendentes(options?: any): AxiosPromise<void> {
      return localVarFp.getPesquisarExoneradosPendentes(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarExoneradosRegularizados(options?: any): AxiosPromise<void> {
      return localVarFp.getPesquisarExoneradosRegularizados(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarLancamentos(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getPesquisarLancamentos(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarObitosPendentes(options?: any): AxiosPromise<void> {
      return localVarFp.getPesquisarObitosPendentes(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarObitosRegularizados(options?: any): AxiosPromise<void> {
      return localVarFp.getPesquisarObitosRegularizados(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarPorOrgao(options?: any): AxiosPromise<void> {
      return localVarFp.getPesquisarPorOrgao(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisarVencidosPendentes(options?: any): AxiosPromise<void> {
      return localVarFp.getPesquisarVencidosPendentes(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisar_8(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getPesquisar_8(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPesquisar_9(options?: any): AxiosPromise<void> {
      return localVarFp.getPesquisar_9(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelatorio(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getRelatorio(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelatorioLanc(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getRelatorioLanc(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelatorio_10(options?: any): AxiosPromise<void> {
      return localVarFp.getRelatorio_10(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRelatorio_11(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getRelatorio_11(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSimulacaoRenegociacaoSegurados(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getSimulacaoRenegociacaoSegurados(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} sigla
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUnidadeGestora(sigla: string, options?: any): AxiosPromise<void> {
      return localVarFp.getUnidadeGestora(sigla, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetcalcularParcelasSegurado} getcalcularParcelasSegurado
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getcalcularParcelasSegurado(getcalcularParcelasSegurado: GetcalcularParcelasSegurado, options?: any): AxiosPromise<void> {
      return localVarFp
        .getcalcularParcelasSegurado(getcalcularParcelasSegurado, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getcalcularValoresSegurado(options?: any): AxiosPromise<void> {
      return localVarFp.getcalcularValoresSegurado(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getdocumentoModeloCheckList(options?: any): AxiosPromise<void> {
      return localVarFp.getdocumentoModeloCheckList(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getfaq(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getfaq(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ForgotPassword} forgotPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getforgotPassword(forgotPassword: ForgotPassword, options?: any): AxiosPromise<void> {
      return localVarFp.getforgotPassword(forgotPassword, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {LoginForm} loginForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getlogin(loginForm: LoginForm, options?: any): AxiosPromise<void> {
      return localVarFp.getlogin(loginForm, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getpesquisar(options?: any): AxiosPromise<void> {
      return localVarFp.getpesquisar(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getpesquisar_12(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getpesquisar_12(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getpesquisar_13(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getpesquisar_13(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getpropostasTomador(options?: any): AxiosPromise<void> {
      return localVarFp.getpropostasTomador(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {Pesquisar} pesquisar
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getrelatorio(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
      return localVarFp.getrelatorio(pesquisar, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {GetRelatorioCalculadoraDto} getRelatorioCalculadoraDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getrelatorio_14(getRelatorioCalculadoraDto: GetRelatorioCalculadoraDto, options?: any): AxiosPromise<void> {
      return localVarFp.getrelatorio_14(getRelatorioCalculadoraDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getsimulacoesTomador(options?: any): AxiosPromise<void> {
      return localVarFp.getsimulacoesTomador(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {LoginForm} loginForm
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getverifyPassword(loginForm: LoginForm, options?: any): AxiosPromise<void> {
      return localVarFp.getverifyPassword(loginForm, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    grupoFolha(options?: any): AxiosPromise<void> {
      return localVarFp.grupoFolha(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    importar(options?: any): AxiosPromise<void> {
      return localVarFp.importar(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {object} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    list(body: object, options?: any): AxiosPromise<void> {
      return localVarFp.list(body, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listParcelas(financeiroParcelaListEntity: FinanceiroParcelaListEntity, options?: any): AxiosPromise<void> {
      return localVarFp.listParcelas(financeiroParcelaListEntity, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listParcelasACalcular(financeiroParcelaListEntity: FinanceiroParcelaListEntity, options?: any): AxiosPromise<void> {
      return localVarFp.listParcelasACalcular(financeiroParcelaListEntity, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listParcelasARegularizar(financeiroParcelaListEntity: FinanceiroParcelaListEntity, options?: any): AxiosPromise<void> {
      return localVarFp
        .listParcelasARegularizar(financeiroParcelaListEntity, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listParcelasArrecadacao(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.listParcelasArrecadacao(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listParcelasNaoDescontadas(financeiroParcelaListEntity: FinanceiroParcelaListEntity, options?: any): AxiosPromise<void> {
      return localVarFp
        .listParcelasNaoDescontadas(financeiroParcelaListEntity, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} userId
     * @param {string} tomador
     * @param {string} testemunha
     * @param {string} gestor
     * @param {string} page
     * @param {string} perPage
     * @param {number} institutoId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listar(
      userId: string,
      tomador: string,
      testemunha: string,
      gestor: string,
      page: string,
      perPage: string,
      institutoId: number,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .listar(userId, tomador, testemunha, gestor, page, perPage, institutoId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listarUnidadeGestora(options?: any): AxiosPromise<void> {
      return localVarFp.listarUnidadeGestora(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {NegarProposta} negarProposta
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    negarProposta(negarProposta: NegarProposta, options?: any): AxiosPromise<void> {
      return localVarFp.negarProposta(negarProposta, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ConsultaCalculadoraDto} consultaCalculadoraDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novaConsultaCalculadora(consultaCalculadoraDto: ConsultaCalculadoraDto, options?: any): AxiosPromise<void> {
      return localVarFp.novaConsultaCalculadora(consultaCalculadoraDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novaProposta(options?: any): AxiosPromise<void> {
      return localVarFp.novaProposta(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novaRenegociacaoExonerados(options?: any): AxiosPromise<void> {
      return localVarFp.novaRenegociacaoExonerados(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novaRenegociacaoFalecidos(options?: any): AxiosPromise<void> {
      return localVarFp.novaRenegociacaoFalecidos(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novaSimulacao(options?: any): AxiosPromise<void> {
      return localVarFp.novaSimulacao(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    novoAcordo(options?: any): AxiosPromise<void> {
      return localVarFp.novoAcordo(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orgao(options?: any): AxiosPromise<void> {
      return localVarFp.orgao(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    paises(options?: any): AxiosPromise<void> {
      return localVarFp.paises(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post(options?: any): AxiosPromise<void> {
      return localVarFp.post(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLog(options?: any): AxiosPromise<void> {
      return localVarFp.postLog(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNotificacoes(options?: any): AxiosPromise<void> {
      return localVarFp.postNotificacoes(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postNotificao(options?: any): AxiosPromise<void> {
      return localVarFp.postNotificao(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    post_15(options?: any): AxiosPromise<void> {
      return localVarFp.post_15(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.put(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putContratoGerado(options?: any): AxiosPromise<void> {
      return localVarFp.putContratoGerado(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putLancamento(financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas, options?: any): AxiosPromise<void> {
      return localVarFp.putLancamento(financeiroCompetenciaParcelas, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    put_16(id: number, options?: any): AxiosPromise<void> {
      return localVarFp.put_16(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    RealizaEnvioDescontoParaSisprev(options?: any): AxiosPromise<void> {
      return localVarFp.RealizaEnvioDescontoParaSisprev(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recalcularListaDeParcelas(options?: any): AxiosPromise<void> {
      return localVarFp.recalcularListaDeParcelas(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    receber(options?: any): AxiosPromise<void> {
      return localVarFp.receber(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {RecoverPassword} recoverPassword
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    recoverPassword(recoverPassword: RecoverPassword, options?: any): AxiosPromise<void> {
      return localVarFp.recoverPassword(recoverPassword, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {RetornoExportacaoMargemDto} retornoExportacaoMargemDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retornoExportacaoMargem(retornoExportacaoMargemDto: RetornoExportacaoMargemDto, options?: any): AxiosPromise<void> {
      return localVarFp.retornoExportacaoMargem(retornoExportacaoMargemDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    solicitar(options?: any): AxiosPromise<void> {
      return localVarFp.solicitar(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ultimaParcelaData(options?: any): AxiosPromise<void> {
      return localVarFp.ultimaParcelaData(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    unPutLancamento(financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas, options?: any): AxiosPromise<void> {
      return localVarFp.unPutLancamento(financeiroCompetenciaParcelas, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {UpdateIntegracoeDto} updateIntegracoeDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    update(id: number, updateIntegracoeDto: UpdateIntegracoeDto, options?: any): AxiosPromise<void> {
      return localVarFp.update(id, updateIntegracoeDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadedFile(options?: any): AxiosPromise<void> {
      return localVarFp.uploadedFile(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verifica(id: string, options?: any): AxiosPromise<void> {
      return localVarFp.verifica(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} cpf
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    verificaSeExisteTomador(cpf: string, options?: any): AxiosPromise<void> {
      return localVarFp.verificaSeExisteTomador(cpf, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _delete(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      ._delete(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _delete_1(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      ._delete_1(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public _delete_2(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      ._delete_2(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public alterarStatusRecalculo(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .alterarStatusRecalculo(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {AprovarProposta} aprovarProposta
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public aprovarProposta(aprovarProposta: AprovarProposta, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .aprovarProposta(aprovarProposta, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {AssinaDocumentoDto} assinaDocumentoDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public assinaDocumento(assinaDocumentoDto: AssinaDocumentoDto, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .assinaDocumento(assinaDocumentoDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} cpf
   * @param {number} matricula
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public atualizaMargemSegurado(cpf: string, matricula: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .atualizaMargemSegurado(cpf, matricula, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public atualizaStatusRenegociacao(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .atualizaStatusRenegociacao(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public atualizaVencimentoRenegociacao(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .atualizaVencimentoRenegociacao(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} userId
   * @param {string} financeiroId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public atualizar(userId: string, financeiroId: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .atualizar(userId, financeiroId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public baixaLancamentosManualmente(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .baixaLancamentosManualmente(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public bancos(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .bancos(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public buscaPorInstitutoId(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .buscaPorInstitutoId(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public buscaPorInstitutoId_3(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .buscaPorInstitutoId_3(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public buscarNotificacoes(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .buscarNotificacoes(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cadastraAntecipacao(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .cadastraAntecipacao(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CalcularProposta} calcularProposta
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public calcularProposta(calcularProposta: CalcularProposta, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .calcularProposta(calcularProposta, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public carregarDadosParcelas(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .carregarDadosParcelas(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public carregarParcelas(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .carregarParcelas(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public carregarParcelas_4(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .carregarParcelas_4(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cidades(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .cidades(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public confirmarPagamentoExonenacoesRegularizadas(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .confirmarPagamentoExonenacoesRegularizadas(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public confirmarPagamentoObitosRegularizados(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .confirmarPagamentoObitosRegularizados(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public confirmarPagamentoParcelasVencidasRegularizadas(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .confirmarPagamentoParcelasVencidasRegularizadas(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public create(body: object, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .create(body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {CreateIntegracoeDto} createIntegracoeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public create_5(id: number, createIntegracoeDto: CreateIntegracoeDto, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .create_5(id, createIntegracoeDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public criar(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .criar(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deleteNotificacoes(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .deleteNotificacoes(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public download(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .download(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {FindOneIntegracoeDto} findOneIntegracoeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public findOne(id: string, findOneIntegracoeDto: FindOneIntegracoeDto, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .findOne(id, findOneIntegracoeDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gerar(body: object, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .gerar(body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gerarRelatorioAmortizacao(
    pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .gerarRelatorioAmortizacao(pesquisarRelatorioFinanceiroDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gerarRelatorioFundoGarantido(
    pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .gerarRelatorioFundoGarantido(pesquisarRelatorioFinanceiroDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gerarRelatorioOscilacaoDeRiscos(
    pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .gerarRelatorioOscilacaoDeRiscos(pesquisarRelatorioFinanceiroDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gerarRelatorioParcelasConsolidadas(
    pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .gerarRelatorioParcelasConsolidadas(pesquisarRelatorioFinanceiroDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gerarRelatorioParcelasRecebidasPorCompetencia(
    pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .gerarRelatorioParcelasRecebidasPorCompetencia(pesquisarRelatorioFinanceiroDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gerarRelatorioRentabilidadeConsolidado(
    pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .gerarRelatorioRentabilidadeConsolidado(pesquisarRelatorioFinanceiroDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gerarRelatorioRentabilidadePorCompetencia(
    pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .gerarRelatorioRentabilidadePorCompetencia(pesquisarRelatorioFinanceiroDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {PesquisarRelatorioFinanceiroDto} pesquisarRelatorioFinanceiroDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gerarRelatorioTaxaAdministracaoConsolidado(
    pesquisarRelatorioFinanceiroDto: PesquisarRelatorioFinanceiroDto,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .gerarRelatorioTaxaAdministracaoConsolidado(pesquisarRelatorioFinanceiroDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public gerarRenegociacao(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .gerarRenegociacao(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getAllCadastro(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getAllCadastro(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getAnalisesAdm(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getAnalisesAdm(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getAnalisesEmprestimo(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getAnalisesEmprestimo(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getAnalisesPropostas(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getAnalisesPropostas(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getBancos(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getBancos(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getBoleto(body: object, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getBoleto(body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetCalcularFloatDto} getCalcularFloatDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getCalcularFloat(getCalcularFloatDto: GetCalcularFloatDto, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getCalcularFloat(getCalcularFloatDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getCalcularIOF(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getCalcularIOF(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getCalcularParcelasAAntecipar(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getCalcularParcelasAAntecipar(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getCarregarDadosParcelasAntecipacao(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getCarregarDadosParcelasAntecipacao(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} cep
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getCep(cep: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getCep(cep, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getContratosComParcelasEmDivida(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getContratosComParcelasEmDivida(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getContratosComParcelasVencidas(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getContratosComParcelasVencidas(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getContratosParaAntecipacao(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getContratosParaAntecipacao(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getCriteriosEligibilidade(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getCriteriosEligibilidade(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetDocumentoAssinado} getDocumentoAssinado
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getDocumentoAssinado(getDocumentoAssinado: GetDocumentoAssinado, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getDocumentoAssinado(getDocumentoAssinado, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getGerarRelatorioPDF(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getGerarRelatorioPDF(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getHello(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getHello(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getId(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getId(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getId_6(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getId_6(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getId_7(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getId_7(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetIndicadoresProposta} getIndicadoresProposta
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getIndicadoresProposta(getIndicadoresProposta: GetIndicadoresProposta, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getIndicadoresProposta(getIndicadoresProposta, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getLog(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getLog(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getNotificacoes(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getNotificacoes(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getParametros(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getParametros(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} sigla
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getParametrosCalculadora(sigla: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getParametrosCalculadora(sigla, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getParametrosRenegociacao(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getParametrosRenegociacao(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getParcelasSeguradoRenegociacao(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getParcelasSeguradoRenegociacao(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPesquisar(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getPesquisar(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPesquisarAntecipacoes(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getPesquisarAntecipacoes(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPesquisarExoneradosPendentes(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getPesquisarExoneradosPendentes(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPesquisarExoneradosRegularizados(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getPesquisarExoneradosRegularizados(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPesquisarLancamentos(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getPesquisarLancamentos(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPesquisarObitosPendentes(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getPesquisarObitosPendentes(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPesquisarObitosRegularizados(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getPesquisarObitosRegularizados(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPesquisarPorOrgao(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getPesquisarPorOrgao(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPesquisarVencidosPendentes(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getPesquisarVencidosPendentes(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPesquisar_8(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getPesquisar_8(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPesquisar_9(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getPesquisar_9(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getRelatorio(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getRelatorio(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getRelatorioLanc(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getRelatorioLanc(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getRelatorio_10(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getRelatorio_10(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getRelatorio_11(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getRelatorio_11(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getSimulacaoRenegociacaoSegurados(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getSimulacaoRenegociacaoSegurados(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} sigla
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getUnidadeGestora(sigla: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getUnidadeGestora(sigla, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetcalcularParcelasSegurado} getcalcularParcelasSegurado
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getcalcularParcelasSegurado(
    getcalcularParcelasSegurado: GetcalcularParcelasSegurado,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .getcalcularParcelasSegurado(getcalcularParcelasSegurado, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getcalcularValoresSegurado(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getcalcularValoresSegurado(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getdocumentoModeloCheckList(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getdocumentoModeloCheckList(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getfaq(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getfaq(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ForgotPassword} forgotPassword
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getforgotPassword(forgotPassword: ForgotPassword, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getforgotPassword(forgotPassword, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {LoginForm} loginForm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getlogin(loginForm: LoginForm, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getlogin(loginForm, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getpesquisar(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getpesquisar(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getpesquisar_12(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getpesquisar_12(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getpesquisar_13(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getpesquisar_13(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getpropostasTomador(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getpropostasTomador(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {Pesquisar} pesquisar
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getrelatorio(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getrelatorio(pesquisar, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {GetRelatorioCalculadoraDto} getRelatorioCalculadoraDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getrelatorio_14(getRelatorioCalculadoraDto: GetRelatorioCalculadoraDto, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getrelatorio_14(getRelatorioCalculadoraDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getsimulacoesTomador(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getsimulacoesTomador(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {LoginForm} loginForm
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getverifyPassword(loginForm: LoginForm, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getverifyPassword(loginForm, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public grupoFolha(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .grupoFolha(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public importar(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .importar(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {object} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public list(body: object, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .list(body, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public listParcelas(financeiroParcelaListEntity: FinanceiroParcelaListEntity, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .listParcelas(financeiroParcelaListEntity, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public listParcelasACalcular(financeiroParcelaListEntity: FinanceiroParcelaListEntity, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .listParcelasACalcular(financeiroParcelaListEntity, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public listParcelasARegularizar(financeiroParcelaListEntity: FinanceiroParcelaListEntity, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .listParcelasARegularizar(financeiroParcelaListEntity, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public listParcelasArrecadacao(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .listParcelasArrecadacao(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {FinanceiroParcelaListEntity} financeiroParcelaListEntity
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public listParcelasNaoDescontadas(financeiroParcelaListEntity: FinanceiroParcelaListEntity, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .listParcelasNaoDescontadas(financeiroParcelaListEntity, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} userId
   * @param {string} tomador
   * @param {string} testemunha
   * @param {string} gestor
   * @param {string} page
   * @param {string} perPage
   * @param {number} institutoId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public listar(
    userId: string,
    tomador: string,
    testemunha: string,
    gestor: string,
    page: string,
    perPage: string,
    institutoId: number,
    options?: RawAxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .listar(userId, tomador, testemunha, gestor, page, perPage, institutoId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public listarUnidadeGestora(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .listarUnidadeGestora(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {NegarProposta} negarProposta
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public negarProposta(negarProposta: NegarProposta, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .negarProposta(negarProposta, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ConsultaCalculadoraDto} consultaCalculadoraDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public novaConsultaCalculadora(consultaCalculadoraDto: ConsultaCalculadoraDto, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .novaConsultaCalculadora(consultaCalculadoraDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public novaProposta(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .novaProposta(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public novaRenegociacaoExonerados(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .novaRenegociacaoExonerados(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public novaRenegociacaoFalecidos(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .novaRenegociacaoFalecidos(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public novaSimulacao(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .novaSimulacao(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public novoAcordo(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .novoAcordo(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public orgao(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .orgao(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public paises(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .paises(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public post(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .post(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public postLog(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .postLog(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public postNotificacoes(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .postNotificacoes(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public postNotificao(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .postNotificao(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public post_15(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .post_15(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public put(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .put(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public putContratoGerado(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .putContratoGerado(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public putLancamento(financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .putLancamento(financeiroCompetenciaParcelas, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public put_16(id: number, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .put_16(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public RealizaEnvioDescontoParaSisprev(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .RealizaEnvioDescontoParaSisprev(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public recalcularListaDeParcelas(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .recalcularListaDeParcelas(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public receber(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .receber(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {RecoverPassword} recoverPassword
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public recoverPassword(recoverPassword: RecoverPassword, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .recoverPassword(recoverPassword, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {RetornoExportacaoMargemDto} retornoExportacaoMargemDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public retornoExportacaoMargem(retornoExportacaoMargemDto: RetornoExportacaoMargemDto, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .retornoExportacaoMargem(retornoExportacaoMargemDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public solicitar(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .solicitar(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public ultimaParcelaData(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .ultimaParcelaData(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public unPutLancamento(financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .unPutLancamento(financeiroCompetenciaParcelas, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {UpdateIntegracoeDto} updateIntegracoeDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public update(id: number, updateIntegracoeDto: UpdateIntegracoeDto, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .update(id, updateIntegracoeDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public uploadedFile(options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .uploadedFile(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public verifica(id: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .verifica(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} cpf
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public verificaSeExisteTomador(cpf: string, options?: RawAxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .verificaSeExisteTomador(cpf, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
