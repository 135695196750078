/**
 * grpe: {
 *  vencimento: 2023-11-19T00:00:00.000Z
 *  valor: 1000.00
 * }
 * juros: {
 *  tipo: 'Ao Mês - Cálculo Diário' | 'Ao Mês - Não Cumulativo',
 *  valor: '1' // Percentage
 * },
 * multa: {
 *  apos: 1 // Mês,
 *  valor: '2' // Percentage
 * }
 */
export function calculaJurosEMulta(grpe, juros, multa) {
    const vencimento = new Date(grpe.vencimento);
    const hoje = new Date();
    const diffTime = Math.abs(hoje - vencimento);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays > 30) {
        let valor = grpe.valor;
        let jurosValor = 0;
        let multaValor = 0;
        const percentualJuros = juros.valor / 100

        if (juros.tipo == 'aoMesCalculoDiario') {
            // const jurosDiario = (1 + (juros.valor / 100)) ** (1 / 30) - 1;
            jurosValor = valor * (percentualJuros * diffDays);
        } else if (juros.tipo == 'aoMesNaoCumulativo') {
            // const jurosMensal = (1 + (juros.valor / 100)) - 1;
            jurosValor = valor * (percentualJuros * (diffDays / 30));
        }

        if (multa.apos <= diffDays) {
            multaValor = valor * (multa.valor / 100);
        }

        return {
            juros: jurosValor,
            multa: multaValor,
            total: valor + jurosValor + multaValor,
        };
    } else {
        return {
            juros: 0,
            multa: 0,
            total: grpe.valor,
        };
    }
}