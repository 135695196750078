<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import Ripple from 'vue-ripple-directive'
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarData, formatarValor, validarData } from '@/libs/utils'
  import { formatarCompetencia } from '@core/utils/utils.js'
  import VueHtml2pdf from 'vue-html2pdf'
  import Cleave from 'vue-cleave-component'
  import AutenticaSenha from '@/views/pages/auth/autenticaSenha'
  import pdfMake from 'pdfmake/build/pdfmake'
  import pdfFonts from 'pdfmake/build/vfs_fonts'
  import VisualizarLancamentosArrecadacao from '@/views/pages/financeiro/arrecadacao/VisualizarLancamentosArrecadacao.vue'
  import ArrecadacaoGeraGuia from './ArrecadacaoGeraGuia.vue'
  import { buscarDadosGuia, gerarArquivoGuia, gerarDadosGuia } from '../../../../utils/guia/geracao'
  import { pipe } from 'fp-ts/lib/function'
  import { either } from 'fp-ts'
  import { geraBoleto, adicionaBoletoNaGeracaoDeRemessa } from '@/utils/boleto/index'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      VueHtml2pdf,
      pdfMake,
      pdfFonts,
      Cleave,
      AutenticaSenha,
      ArrecadacaoGeraGuia,

      // Utils
      formatarData,
      formatarValor,
      validarData,

      VisualizarLancamentosArrecadacao,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        formatarData,
        formatarValor,
        formatarCompetencia,
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        usuario: {
          role: null,
          instituto: null,
        },
        table: {
          items: [],
          fields: [
            { key: 'opcoes', label: 'Opções' },
            { key: 'codigo', label: 'N.º da Arrecadação', sortable: true },
            { key: 'competencia', label: 'Competência', sortable: true },
            { key: 'orgao.nome', label: 'Órgão', sortable: true },
            { key: 'dataVencimento', label: 'Data Vencimento', sortable: true },
            { key: 'status', label: 'Status', sortable: true },
            { key: 'valorTotal', label: 'Valor Total da Guia', sortable: true },
          ],
          isBusy: false,
          sort: {
            by: '',
            desc: false,
            direction: 'asc',
          },
          styles: {
            status: [
              {
                GRPEPago: 'success',
                GRPEGerado: 'warning',
                GRPEExpirado: 'info',
              },
            ],
          },
        },
        pesquisar: {
          pagination: {
            currentPage: 1,
            total: 0,
            perPage: 10,
            options: [10, 25, 50, 100],
          },
          isBusy: false,
          mes: {
            value: new Date().getMonth() + 1,
            opcoes: [
              { value: 1, text: 'Janeiro' },
              { value: 2, text: 'Fevereiro' },
              { value: 3, text: 'Março' },
              { value: 4, text: 'Abril' },
              { value: 5, text: 'Maio' },
              { value: 6, text: 'Junho' },
              { value: 7, text: 'Julho' },
              { value: 8, text: 'Agosto' },
              { value: 9, text: 'Setembro' },
              { value: 10, text: 'Outubro' },
              { value: 11, text: 'Novembro' },
              { value: 12, text: 'Dezembro' },
            ],
          },
          exercicio: {
            value: {
              value: new Date().getFullYear(),
              label: new Date().getFullYear(),
            },
            opcoes: [],
          },
          orgao: {
            value: undefined,
            opcoes: [],
          },
        },
        dadosArrecadacao: {},
      }
    },
    watch: {
      'pesquisar.pagination.currentPage': function () {
        this.tables().fetchItems()
      },
      'pesquisar.pagination.perPage': function () {
        this.tables().fetchItems()
      },
    },
    async mounted() {
      this.usuario.role = this.userData.role
      this.usuario.instituto = this.userData.institutoSelecionado
      this.pesquisar.exercicio.opcoes = this.exercicios().listarOpcoes()
      this.pesquisar.orgao.opcoes = await this.orgaos().listarOpcoes(this.usuario.instituto)
    },
    methods: {
      formatarStatus(status) {
        if (status == 'NaoGerado') {
          return 'Não Gerado'
        }

        return status
      },
      mostrarBotaoBaixar(tipoGuia, s3Url) {
        if (tipoGuia == 'guia') {
          return true
        } else if (tipoGuia == 'boleto' && s3Url) {
          return true
        }
        return false
      },
      mostrarBotaoConfirmarPagamento(tipoGuia, boletos, status) {
        if (status == 'Pago') {
          return false
        }

        return this.mostrarBotaoBaixar(tipoGuia, boletos)
      },
      tables() {
        return {
          fetchItems: async () => {
            this.table.isBusy = true
            const response = await this.parcelas().listarParcelas(
              this.pesquisar.mes.value,
              this.pesquisar.exercicio.value.value,
              this.pesquisar.orgao.value,
            )
            this.table.items = response
            this.table.isBusy = false
          },
        }
      },
      parcelas() {
        return {
          items: [], // Inicialize um array vazio para armazenar os resultados

          listarParcelas: async (mes, ano, orgaos) => {
            const response = await useJwt.get('financeiro/arrecadacao/getArrecadacao', {
              params: {
                mes,
                ano,
                orgaoIds: orgaos,
                institutoId: this.userData.institutoSelecionado,
              },
            })

            if (response.data.length == 0) {
              this.$message.error('Não foi encontrado nenhum registro')
            }
            return response.data
          },
        }
      },
      orgaos() {
        return {
          listarOpcoes: async (institutoId) => (await useJwt.get(`cadastro/orgao/buscaPorInstitutoId/${institutoId}`)).data,
        }
      },
      exercicios() {
        return {
          listarOpcoes: () => {
            const ano = new Date().getFullYear()
            const anoOpcao = [ano + 2]
            anoOpcao.push(ano + 1)
            anoOpcao.push(ano)

            for (let i = 1; i < 6; i++) {
              anoOpcao.push(ano - i)
            }

            anoOpcao.sort((a, b) => a - b)

            return anoOpcao.map((ano) => ({ value: ano, label: ano }))
          },
        }
      },
      visualizarLancamentos(item) {
        this.dadosArrecadacao = item
        this.$refs['modal-detalhes-Arrecadacao'].show()
      },
      async baixarBoleto(item) {
        const response = await useJwt.post('aws/getDownladArquivoAwsCrypt', { s3Key: item.s3Key })
        const data = response.data
        const buffer = new Uint8Array(data.Body.data) // Extrai o buffer dos dados
        const blob = new Blob([buffer], { type: data.ContentType })
        saveAs(blob, 'boleto.pdf')
        const url = window.URL.createObjectURL(blob)
        window.open(url, '_blank')
        setTimeout(() => window.URL.revokeObjectURL(url), 100)
      },
      async baixarGuia(id) {
        this.$message.info('Aguarde enquanto o arquivo é gerado')
        const tarefa = await buscarDadosGuia(id)()

        const result = await pipe(
          tarefa,
          either.map(({ data: resposta }) =>
            gerarDadosGuia({
              grpe: resposta.item?.grpe,
              orgao: resposta.item?.orgao,
              instituto: {
                conta: resposta.item?.instituto?.contas?.[0],
                banco: resposta.item?.instituto?.bancos,
                dados: resposta.item?.instituto?.dados,
              },
            }),
          ),
          either.map(gerarArquivoGuia),
          either.chain(async (arquivo) => {
            const arch = await arquivo
            const out1 = either.getOrElseW(() => new Blob())(arch)
            const out = either.getOrElseW(() => new Blob())(out1)
            const formData = new FormData()
            formData.append('file', out, 'guia')
            const res = (await useJwt.postDocumento(`financeiro/processaPdf`, () => {}, formData)).data.pdf

            const uint8Array = new Uint8Array(res.data)
            const blob = new Blob([uint8Array])

            saveAs(blob, 'ConsigPrev-Arrecadacao.pdf')
          }),
          either.mapLeft((e) => console.error('erro', e)),
        )
      },
      visualizarLancamentos(item) {
        this.dadosArrecadacao = item
        this.$refs['modal-detalhes-Arrecadacao'].show()
      },
      async confirmarPagamento(item) {
        try {
          const response = (
            await useJwt.post('financeiro/Arrecadacao/confirmarPagamento', {
              id: item,
            })
          ).data

          if (response.status != 201) {
            this.$message.error('Não foi possível confirmar o pagamento')
          } else {
            this.$message.success('Pagamento confirmado com sucesso')
          }

          await this.tables().fetchItems()
        } catch (e) {
          this.$message.error('Não foi possível confirmar o pagamento')
        }
      },
    },
  }
</script>

<template>
  <b-card-code no-body>
    <b-card-body>
      <!-- Pesquisa -->
      <b-row>
        <b-col md="2">
          <b-form-group label="Mês" label-for="mes">
            <v-select-pt
              id="mes"
              v-model="pesquisar.mes.value"
              name="mes"
              :options="pesquisar.mes.opcoes"
              :reduce="(option) => option.value"
              :clearable="false"
              label="text"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Ano" label-for="ano">
            <v-select-pt
              v-model="pesquisar.exercicio.value"
              name="ano"
              :options="pesquisar.exercicio.opcoes"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Órgão" label-for="orgao">
            <v-select-pt
              v-model="pesquisar.orgao.value"
              name="orgao"
              :options="pesquisar.orgao.opcoes"
              :reduce="(option) => option.id"
              label="nome"
              @keydown.enter="tables().fetchItems()"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            :disabled="pesquisar.isBusy"
            variant="primary"
            style="margin-top: 21px; width: 160px"
            @click="tables().fetchItems()"
          >
            {{ !pesquisar.isBusy ? 'Pesquisar' : '' }}
            <b-spinner v-if="pesquisar.isBusy" small />
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>

    <b-table
      v-show="table.items.length > 0"
      id="tableGRPE"
      striped
      class="position-relative"
      :per-page="pesquisar.pagination.perPage"
      :items="table.items"
      :fields="table.fields"
      :sort-by.sync="table.sort.by"
      :sort-desc.sync="table.sort.desc"
      :sort-direction="table.sort.direction"
      :busy="table.isBusy"
      :current-page="pesquisar.pagination.currentPage"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Carregando...</strong>
        </div>
      </template>

      <template #cell(select)="row">
        <template>
          <b-form-checkbox :key="row.item.id" v-model="itemCheckSelecionado" :value="row.item.id" />
        </template>
      </template>

      <template #cell(opcoes)="row">
        <b-dropdown variant="link" no-caret toggle-class="p-1" left>
          <template #button-content>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              title="Este botão contém as opções disponíveis para este registro"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
          </template>

          <b-dropdown-item @click="visualizarLancamentos(row.item)">
            <feather-icon icon="EyeIcon" size="16" />
            <span class="align-middle ml-50">Visualizar Lançamentos</span>
          </b-dropdown-item>

          <!-- Tela de Geração da Guia -->

          <b-dropdown-item
            v-if="mostrarBotaoBaixar(row.item.tipoBaixa, row.item.s3url)"
            @click="row.item.tipoBaixa == 'guia' ? baixarGuia(row.item.id) : baixarBoleto(row.item)"
          >
            <feather-icon icon="FileIcon" size="16" />
            <span class="align-middle ml-50">{{ row.item.tipoBaixa == 'guia' ? 'Baixar Guia' : 'Baixar Boleto' }}</span>
          </b-dropdown-item>

          <!-- <template>
            <VisualizarLancamentosArrecadacao ref="visualizarLancamentosArrecadacao"/>
          </template> -->

          <b-dropdown-item
            v-if="mostrarBotaoConfirmarPagamento(row.item.tipoBaixa, row.item.Boleto, row.item.status)"
            @click="confirmarPagamento(row.item.id)"
          >
            <feather-icon icon="CheckSquareIcon" size="16" />
            <span class="align-middle ml-50">Confirmar Pagamento</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #cell(codigo)="row">
        {{ row.item.codigo }}
      </template>

      <template #cell(dataVencimento)="row">
        {{
          row.item.Boleto.length > 0 ? formatarData(row.item.Boleto[0].dataVencimento) : formatarData(row.item.dataVencimento)
        }}
      </template>

      <template #cell(competencia)="row">
        {{ row.item.competencia ? formatarCompetencia(row.item.competencia) : undefined }}
      </template>

      <template #cell(valorTotal)="row">
        {{
          new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
          }).format(row.item.valorTotal)
        }}
      </template>

      <template #cell(status)="row">
        {{ formatarStatus(row.item.status) }}
      </template>

      <template #cell(pagamento)="row">
        {{ row.item.pagamento == null ? '' : formatarData(row.item.pagamento) }}
      </template>

      <template #cell(valorPago)="row">
        {{
          row.item.valorPago == null
            ? ''
            : new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(parseFloat(row.item.valorPago))
        }}
      </template>
    </b-table>

    <b-card-body v-if="table.items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="pesquisar.pagination.perPage"
          size="sm"
          inline
          :options="pesquisar.pagination.options"
        />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="pesquisar.pagination.currentPage"
        :total-rows="table.items.length"
        :per-page="pesquisar.pagination.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>
    <!-- Modal visualizar Lançamentos -->
    <b-modal
      id="modal-detalhes-Arrecadacao"
      ref="modal-detalhes-Arrecadacao"
      no-enforce-focus
      :auto-focus="false"
      centered
      size="xl"
      title="Detalhes dessa Arrecadação"
      hide-footer
      ok-only
    >
      <visualizar-lancamentos-arrecadacao :dado-arrecadacao-filho="dadosArrecadacao" />
    </b-modal>
  </b-card-code>
</template>
