import useJwt from '../../auth/jwt/useJwt'
import { AxiosInstance } from 'axios'
import {
  Configuration,
  ArrecadacaoApiFactory,
  BoletoApiFactory,
  UsuarioApiFactory,
  InstitutoApiFactory,
  TomadorApiFactory,
  ReconhecimentoFacialApiFactory,
} from '.'

const config = new Configuration({
  accessToken: useJwt.getToken(),
})

export function fetcherFactory<T extends (...args: any[]) => any>(func: T, ...args: Parameters<T>): ReturnType<T> {
  return func(config, '', useJwt.axiosIns)
}

export const ArrecadacaoApi = fetcherFactory(ArrecadacaoApiFactory)
export const BoletoApi = fetcherFactory(BoletoApiFactory)
export const UsuarioApi = fetcherFactory(UsuarioApiFactory)
export const InstitutoApi = fetcherFactory(InstitutoApiFactory)
export const TomadorApi = fetcherFactory(TomadorApiFactory)
export const ReconhecimentoFacialApi = fetcherFactory(ReconhecimentoFacialApiFactory)
