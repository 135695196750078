<template>
  <div>
    <b-card class="card-congratulations" style="margin-bottom: 10px">
      <!-- Cabeçalho -->
      <b-card-text>
        <b-row>
          <b-col md="5" class="mb-1">
            <div>
              <strong
                >Órgão: {{ dadoArrecadacaoFilho.orgao ? dadoArrecadacaoFilho.orgao.nome : 'Órgão não encontrado' }}
              </strong>
            </div>
            <div>
              <strong>Competência: {{ formatarCompetencia(dadoArrecadacaoFilho.competencia) }} </strong>
            </div>
            <div>
              <strong>Vencimento: {{ formatarData(dadoArrecadacaoFilho.dataVencimento) }} </strong>
            </div>
          </b-col>
        </b-row>
      </b-card-text>
      <!-- Campos do formulário -->
      <validation-observer ref="formContratoPendente">
        <b-form>
          <b-row>
            <!-- Total de Parcelas na Guia -->
            <b-col md="3">
              <b-form-group class="label-branco" label="Total de Parcelas na Guia" label-for="createdAt">
                <b-form-input id="createdAt" v-model="dadoArrecadacaoFilho.quantidadeContratos" :disabled="true" />
              </b-form-group>
            </b-col>

            <!-- Juros e Multa -->
            <b-col md="3">
              <b-form-group class="label-branco" label="Juros e Multa" label-for="Juros e Multa">
                <b-form-input
                  id="jurosMulta"
                  :value="dadoArrecadacaoFilho.juros ? formatarValor(dadoArrecadacaoFilho.juros) : ''"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <!-- Valor Total da Guia -->
            <b-col md="3">
              <b-form-group class="label-branco" label="Valor Total" label-for="vlr1Parcela">
                <b-form-input id="valorTotalDaGuia" :value="formatarValor(dadoArrecadacaoFilho.valorTotal)" :disabled="true" />
              </b-form-group>
            </b-col>
          </b-row>

          <div style="margin-bottom: 15px">
            <b-button
              variant="secondary"
              v-if="dadoArrecadacaoFilho.status != 'Pago'"
              class="mr-1"
              v-b-modal="'modalGerarArrecadacao'"
            >
              Gerar Arrecadação
            </b-button>
            <b-button variant="secondary" class="mr-1" @click="visualizarResumoArrecadacao">
              Visualizar Resumo da Arrecadação
            </b-button>
          </div>
        </b-form>
      </validation-observer>
      <!-- Modal Gerar Arrecadação -->
      <b-modal id="modalGerarArrecadacao" centered ref="modalGerarArrecadacao" title="Gerar Arrecadação">
        <p class="mb-0">Selecione o tipo para a geração da Arrecadação:</p>
        <b-form-select v-model="selected" :options="options"></b-form-select>
        <p class="mb-0 mt-1">Selecione a data de vencimento:</p>
        <b-form-group label="" label-for="dataVencimento">
          <validation-provider #default="{ errors }" name="Data de Vecimento Arrecação" rules="required">
            <b-form-datepicker
              id="dataVencimento"
              v-model="selectedDate"
              placeholder="DD/MM/AAAA"
              :hide-header="true"
              :initial-date="new Date()"
              label-help=""
              :min="new Date()"
              label-calendar="Calendário"
              label-close-button="Fechar"
              label-current-month="Mês atual"
              label-next-decade="Próxima década"
              label-next-month="Próximo mês"
              label-next-year="Próximo ano"
              label-prev-decade="Década anterior"
              label-prev-month="Mês anterior"
              label-prev-year="Ano anterior"
              label-select-month="Selecione o mês"
              label-select-year="Selecione o ano"
              label-selected="Selecionado"
              label-today="Hoje"
              :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <template v-slot:modal-footer>
          <b-button variant="primary" @click="gerarArrecadacao">Gerar</b-button>
        </template>
      </b-modal>
    </b-card>

    <b-table
      :items="dadosDaTabela"
      :fields="camposDaTabela"
      :per-page="10"
      :current-page="currentPage"
      @current-change="handleCurrentPageChange"
    >
      <template #cell(competencia)="row">
        {{ row.item.competencia ? formatarCompetencia(row.item.competencia) : undefined }}
      </template>

      <template #cell(valor)="row">
        {{ row.item.competencia ? formatarValor(row.item.valor) : undefined }}
      </template>

      <template #cell(descontoRealizado)="row">
        {{ row.item.competencia ? formatarValor(row.item.descontoRealizado) : undefined }}
      </template>
    </b-table>

    <!-- Paginação do modal -->
    <b-pagination
      first-number
      last-number
      v-model="currentPage"
      :total-rows="dadosDaTabela.length"
      :per-page="10"
      prev-class="prev-item"
      next-class="next-item"
      class="mt-3 justify-content-center"
    >
      <template #prev-text>
        <feather-icon icon="ChevronLeftIcon" size="18" />
      </template>
      <template #next-text>
        <feather-icon icon="ChevronRightIcon" size="18" />
      </template>
    </b-pagination>

    <!-- Exportação de PDF -->
    <div>
      <VisualizarResumoArrecadacao
        ref="VisualizarResumoArrecadacao"
        :table="dadosDaTabela"
        :dadoArrecadacaoFilho="dadoArrecadacaoFilho"
        :camposDaTabela="camposDaTabela"
      />
    </div>

    <!-- Guia de arrecadacao -->
    <div>
      <arrecadacao-gera-guia ref="ArrecadacaoGeraGuia" :parametro="dadoArrecadacaoFilho" />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import useJwt from '@/auth/jwt/useJwt'
  import Cleave from 'vue-cleave-component'
  import vSelect from 'vue-select'
  import DatePicker from '@/views/components/DatePicker.vue'
  import { VueAutosuggest } from 'vue-autosuggest'
  import { formatarData, formatarValor, formatarParaNumber } from '@/libs/utils'
  import { formatarCompetencia } from '@core/utils/utils.js'
  import VisualizarResumoArrecadacao from './VisualizarResumoArrecadacao.vue'
  import VisualizarGuiaArrecadacao from './VisualizarGuiaArrecadacao.vue'
  import ArrecadacaoGeraGuia from './ArrecadacaoGeraGuia.vue'
  import { calculaJurosEMulta } from '@/utils/juros/arrecadacao/calcula-juros-e-multa'
  import { geraBoleto, adicionaBoletoNaGeracaoDeRemessa } from '@/utils/boleto/index'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

  export default defineComponent({
    components: {
      DatePicker,
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      ToastificationContent,
      Cleave,
      vSelect,
      VueAutosuggest,
      formatarData,
      formatarCompetencia,
      formatarValor,
      VisualizarResumoArrecadacao,
      VisualizarGuiaArrecadacao,
      ArrecadacaoGeraGuia,
    },
    props: {
      dadoArrecadacaoFilho: {
        type: Object,
      },
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        calculaJurosEMulta,
        formatarCompetencia,
        formatarValor,
        formatarData,
        selected: null,
        selectedDate: null,
        usuario: {
          role: null,
          instituto: null,
        },
        grpe: {
          dados: {
            dataVencimento: undefined,
            valor: undefined,
          },
          multa: {
            apos: undefined,
            valor: undefined,
          },
          juros: {
            tipo: undefined,
            valor: undefined,
          },
        },
        jurosEMulta: 0,
        dadosDaTabela: [],
        camposDaTabela: [
          { key: 'financeiro.segurado.matricula', label: 'Matrícula' },
          { key: 'financeiro.segurado.tomador.nomeCompleto', label: 'Nome' },
          { key: 'financeiro.segurado.tomador.cpf', label: 'CPF' },
          { key: 'financeiro.codigo', label: 'Nº do Contrato' },
          { key: 'valor', label: 'Valor da Parcela' },
          { key: 'descontoRealizado', label: 'Desconto Realizado' },
          { key: 'competencia', label: 'Período' },
        ],
        currentPage: 1,
        competencia: null,
        vencimento: null,
        options: [
          { value: 'guia', text: 'Guia' },
          { value: 'boleto', text: 'Boleto' },
        ],
      }
    },
    async mounted() {
      this.usuario.role = this.userData.role
      this.usuario.instituto = this.userData.Instituto
      await this.carregarLancamentos()
    },
    methods: {
      async gerarBoleto(dataVencimento) {
        const item = this.dadoArrecadacaoFilho

        this.$message.info('Gerando boleto...')
        const institutoId = this.userData.institutoSelecionado

        const rt = await adicionaBoletoNaGeracaoDeRemessa({
          institutoId: institutoId,
          grpeId: item.id,
          dataVencimento: new Date(dataVencimento).toISOString(),
          tomadorId: item?.Financeiro?.segurado?.tomadorId,
          orgaoId: item.orgaoId,
          geradoPor: 'porGrpe',
        })

        if (rt.status != 200 && rt.status != 201) {
          this.$message.error('Houve um erro ao gerar o boleto!')
          return
        }

        let obj = await geraBoleto(
          institutoId,
          item?.Financeiro?.segurado?.tomadorId,
          {
            valor: Number(item.valorTotal ?? 0),
            dataVencimento: new Date(dataVencimento).toISOString(),
            nossoNumero: `${rt.data.id}`,
            numeroDocumento: `${rt.data.id}`,
          },
          item.orgaoId,
          rt.data.grpeId,
        )
        obj = {
          ...obj,
          grpeId: rt.data.grpeId,
        }
        const resp = await useJwt.post('boleto/geraBoleto', obj)
        this.$message.success('Boleto gerado com sucesso!')

        const data = resp.data.boletoGerado
        const buffer = new Uint8Array(data.Body.data) // Extrai o buffer dos dados
        const blob = new Blob([buffer], { type: data.ContentType })
        saveAs(blob, 'boleto.pdf')
        const url = window.URL.createObjectURL(blob)
        window.open(url, '_blank')
        setTimeout(() => window.URL.revokeObjectURL(url), 100)
      },
      calcularJurosMulta(grpe) {
        const calculo = calculaJurosEMulta(grpe.dados, grpe.juros, grpe.multa)
        return calculo.juros + calculo.multa
      },
      handleCurrentPageChange(page) {
        this.currentPage = page
      },
      async carregarLancamentos() {
        useJwt.pesquisar(`lancamento/getParcelasArrecadacao/${this.dadoArrecadacaoFilho.id}`).then((response) => {
          this.dadosDaTabela = response.data
          this.grpe = {
            dados: {
              vencimento: this.dadoArrecadacaoFilho.dataVencimento,
              valor: this.dadoArrecadacaoFilho.valorTotal,
            },
            multa: {
              apos: this.usuario.instituto.grpeMultaApos,
              valor: this.usuario.instituto.grpeMulta,
            },
            juros: {
              tipo: this.usuario.instituto.grpeTipoJuros,
              valor: this.usuario.instituto.grpeJurosAposVencimento,
            },
          }
          this.jurosEMulta = this.calcularJurosMulta(this.grpe)
        })
      },
      visualizarResumoArrecadacao() {
        this.$refs.VisualizarResumoArrecadacao.exportToPDF()
      },
      gerarArrecadacao() {
        const tipoBaixaSelecionado = this.selected
        const dataVencimentoSelecionada = this.selectedDate

        const parametro = {
          id: this.dadoArrecadacaoFilho.id,
          tipoBaixa: tipoBaixaSelecionado,
          dataVencimento: dataVencimentoSelecionada,
        }
        useJwt
          .post(`financeiro/Arrecadacao/putTipoBoleto`, parametro)
          .then(() => {
            if (tipoBaixaSelecionado == 'guia') {
              this.$refs.ArrecadacaoGeraGuia.vencimentoGrpe = dataVencimentoSelecionada
              this.$refs.ArrecadacaoGeraGuia.renderDoc(dataVencimentoSelecionada)
            } else if (tipoBaixaSelecionado == 'boleto') {
              this.gerarBoleto(dataVencimentoSelecionada)
            } else {
              this.$message.error('Selecione um tipo de baixa!')
              return
            }

            this.$refs.modalGerarArrecadacao.hide()
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  })
</script>

<style>
  .label-branco label {
    color: white;
  }
</style>
