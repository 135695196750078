import docxtemplater from 'docxtemplater'
import { either } from 'fp-ts'

export function criarGeradorDocxGuia(zip: any): either.Either<unknown, docxtemplater> {
  return either.tryCatch(
    () => criarGeradorDocxGuiaUtilitario(zip),
    (error) => {
      return {
        error,
        mensagem: 'Erro ao criar gerador de arquivo docx',
      }
    },
  )
}

function criarGeradorDocxGuiaUtilitario(zip: any) {
  return new docxtemplater(zip)
}
