/* tslint:disable */
/* eslint-disable */
/**
 * Consigprev API
 * API do sistema de consignações previdenciárias - ConsigPrev
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { RegistroReconhecimentoFacial } from '../models';
/**
 * ReconhecimentoFacialApi - axios parameter creator
 * @export
 */
export const ReconhecimentoFacialApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnexoRegistroReconhecimentoFacial: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createAnexoRegistroReconhecimentoFacial', 'body', body)
            const localVarPath = `/reconhecimentoFacial/createAnexoRegistroReconhecimentoFacial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RegistroReconhecimentoFacial} registroReconhecimentoFacial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegistroReconhecimentoFacial: async (registroReconhecimentoFacial: RegistroReconhecimentoFacial, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'registroReconhecimentoFacial' is not null or undefined
            assertParamExists('createRegistroReconhecimentoFacial', 'registroReconhecimentoFacial', registroReconhecimentoFacial)
            const localVarPath = `/reconhecimentoFacial/createRegistroReconhecimentoFacial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(registroReconhecimentoFacial, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnexoRegistroReconhecimentoFacial: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteAnexoRegistroReconhecimentoFacial', 'id', id)
            const localVarPath = `/reconhecimentoFacial/deleteAnexoRegistroReconhecimentoFacial/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnexoRegistroReconhecimentoFacial: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getAnexoRegistroReconhecimentoFacial', 'id', id)
            const localVarPath = `/reconhecimentoFacial/getAnexoRegistroReconhecimentoFacial/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} seguradoId 
         * @param {boolean} includeAnexo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReconhecimentoFacial: async (seguradoId: string, includeAnexo: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'seguradoId' is not null or undefined
            assertParamExists('getReconhecimentoFacial', 'seguradoId', seguradoId)
            // verify required parameter 'includeAnexo' is not null or undefined
            assertParamExists('getReconhecimentoFacial', 'includeAnexo', includeAnexo)
            const localVarPath = `/reconhecimentoFacial/getReconhecimentoFacial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (seguradoId !== undefined) {
                localVarQueryParameter['seguradoId'] = seguradoId;
            }

            if (includeAnexo !== undefined) {
                localVarQueryParameter['includeAnexo'] = includeAnexo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificaRostoDocumentoAnexadoAWS: async (body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('verificaRostoDocumentoAnexadoAWS', 'body', body)
            const localVarPath = `/reconhecimentoFacial/VerificaRostoDocumentoAnexadoAWS`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificaRostoImagem: async (body: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('verificaRostoImagem', 'body', body)
            const localVarPath = `/reconhecimentoFacial/VerificaRostoImagem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReconhecimentoFacialApi - functional programming interface
 * @export
 */
export const ReconhecimentoFacialApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReconhecimentoFacialApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAnexoRegistroReconhecimentoFacial(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAnexoRegistroReconhecimentoFacial(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReconhecimentoFacialApi.createAnexoRegistroReconhecimentoFacial']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {RegistroReconhecimentoFacial} registroReconhecimentoFacial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createRegistroReconhecimentoFacial(registroReconhecimentoFacial: RegistroReconhecimentoFacial, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createRegistroReconhecimentoFacial(registroReconhecimentoFacial, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReconhecimentoFacialApi.createRegistroReconhecimentoFacial']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAnexoRegistroReconhecimentoFacial(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAnexoRegistroReconhecimentoFacial(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReconhecimentoFacialApi.deleteAnexoRegistroReconhecimentoFacial']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnexoRegistroReconhecimentoFacial(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnexoRegistroReconhecimentoFacial(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReconhecimentoFacialApi.getAnexoRegistroReconhecimentoFacial']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} seguradoId 
         * @param {boolean} includeAnexo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReconhecimentoFacial(seguradoId: string, includeAnexo: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReconhecimentoFacial(seguradoId, includeAnexo, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReconhecimentoFacialApi.getReconhecimentoFacial']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verificaRostoDocumentoAnexadoAWS(body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verificaRostoDocumentoAnexadoAWS(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReconhecimentoFacialApi.verificaRostoDocumentoAnexadoAWS']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async verificaRostoImagem(body: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.verificaRostoImagem(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['ReconhecimentoFacialApi.verificaRostoImagem']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * ReconhecimentoFacialApi - factory interface
 * @export
 */
export const ReconhecimentoFacialApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReconhecimentoFacialApiFp(configuration)
    return {
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAnexoRegistroReconhecimentoFacial(body: object, options?: any): AxiosPromise<void> {
            return localVarFp.createAnexoRegistroReconhecimentoFacial(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RegistroReconhecimentoFacial} registroReconhecimentoFacial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRegistroReconhecimentoFacial(registroReconhecimentoFacial: RegistroReconhecimentoFacial, options?: any): AxiosPromise<void> {
            return localVarFp.createRegistroReconhecimentoFacial(registroReconhecimentoFacial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAnexoRegistroReconhecimentoFacial(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAnexoRegistroReconhecimentoFacial(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnexoRegistroReconhecimentoFacial(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getAnexoRegistroReconhecimentoFacial(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} seguradoId 
         * @param {boolean} includeAnexo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReconhecimentoFacial(seguradoId: string, includeAnexo: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.getReconhecimentoFacial(seguradoId, includeAnexo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificaRostoDocumentoAnexadoAWS(body: string, options?: any): AxiosPromise<void> {
            return localVarFp.verificaRostoDocumentoAnexadoAWS(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verificaRostoImagem(body: string, options?: any): AxiosPromise<void> {
            return localVarFp.verificaRostoImagem(body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReconhecimentoFacialApi - object-oriented interface
 * @export
 * @class ReconhecimentoFacialApi
 * @extends {BaseAPI}
 */
export class ReconhecimentoFacialApi extends BaseAPI {
    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReconhecimentoFacialApi
     */
    public createAnexoRegistroReconhecimentoFacial(body: object, options?: RawAxiosRequestConfig) {
        return ReconhecimentoFacialApiFp(this.configuration).createAnexoRegistroReconhecimentoFacial(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegistroReconhecimentoFacial} registroReconhecimentoFacial 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReconhecimentoFacialApi
     */
    public createRegistroReconhecimentoFacial(registroReconhecimentoFacial: RegistroReconhecimentoFacial, options?: RawAxiosRequestConfig) {
        return ReconhecimentoFacialApiFp(this.configuration).createRegistroReconhecimentoFacial(registroReconhecimentoFacial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReconhecimentoFacialApi
     */
    public deleteAnexoRegistroReconhecimentoFacial(id: number, options?: RawAxiosRequestConfig) {
        return ReconhecimentoFacialApiFp(this.configuration).deleteAnexoRegistroReconhecimentoFacial(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReconhecimentoFacialApi
     */
    public getAnexoRegistroReconhecimentoFacial(id: number, options?: RawAxiosRequestConfig) {
        return ReconhecimentoFacialApiFp(this.configuration).getAnexoRegistroReconhecimentoFacial(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} seguradoId 
     * @param {boolean} includeAnexo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReconhecimentoFacialApi
     */
    public getReconhecimentoFacial(seguradoId: string, includeAnexo: boolean, options?: RawAxiosRequestConfig) {
        return ReconhecimentoFacialApiFp(this.configuration).getReconhecimentoFacial(seguradoId, includeAnexo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReconhecimentoFacialApi
     */
    public verificaRostoDocumentoAnexadoAWS(body: string, options?: RawAxiosRequestConfig) {
        return ReconhecimentoFacialApiFp(this.configuration).verificaRostoDocumentoAnexadoAWS(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReconhecimentoFacialApi
     */
    public verificaRostoImagem(body: string, options?: RawAxiosRequestConfig) {
        return ReconhecimentoFacialApiFp(this.configuration).verificaRostoImagem(body, options).then((request) => request(this.axios, this.basePath));
    }
}

