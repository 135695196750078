import { ArrecadacaoApi } from '@/client/api/fetcher'
import { taskEither } from 'fp-ts'

export function buscarDadosGuia(id: number) {
  return taskEither.tryCatch(
    () =>
      ArrecadacaoApi.get({
        id,
        incluirBoleto: true,
        incluirOrgao: true,
        incluirTomador: true,
        incluirInstitutoBanco: true,
        inclurInstituto: true,
        inclurInstitutoContaBancaria: true,
      }),
    (error) => {
      return {
        error,
        mensagem: 'Erro ao buscar dados da guia',
      }
    },
  )
}
