import JSZipUtils from 'jszip-utils'
import PizZip from 'pizzip'
import docxtemplater from 'docxtemplater'
import { taskEither } from 'fp-ts'

export type ArquivoGuia = any

export function carregarArquivoGuia(): taskEither.TaskEither<unknown, ArquivoGuia> {
  return taskEither.tryCatch(carregarArquivoGuiaUtilitario, (error) => error)
}

function carregarArquivoGuiaUtilitario() {
  return new Promise((resolve, reject) => {
    JSZipUtils.getBinaryContent(`/modeloGuia.docx`, (error, content) => {
      if (error) {
        reject(error)
      } else {
        resolve(content)
      }
    })
  })
}
