/* tslint:disable */
/* eslint-disable */
/**
 * Consigprev API
 * API do sistema de consignações previdenciárias - ConsigPrev
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { ComposicaoTaxaHistorico } from '../models';
// @ts-ignore
import { CriteriosElegibilidadeDto } from '../models';
// @ts-ignore
import { DocumentoCheckListSalvarType } from '../models';
// @ts-ignore
import { DocumentoCheckListType } from '../models';
// @ts-ignore
import { GetPesquisarIdadeCriteriosElegibilidadeDto } from '../models';
// @ts-ignore
import { GetTaxasDto } from '../models';
// @ts-ignore
import { GrupoAcesso } from '../models';
// @ts-ignore
import { GrupoAcessoFiltro } from '../models';
// @ts-ignore
import { GrupoAcessoFiltroInst } from '../models';
// @ts-ignore
import { IndicesReferencia } from '../models';
// @ts-ignore
import { Instituto } from '../models';
// @ts-ignore
import { PesquisaIndice } from '../models';
// @ts-ignore
import { Pesquisar } from '../models';
// @ts-ignore
import { PostRegistrarRecursos } from '../models';
// @ts-ignore
import { PutRecursos } from '../models';
// @ts-ignore
import { Recursos } from '../models';
/**
 * InstitutoApi - axios parameter creator
 * @export
 */
export const InstitutoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id)
            const localVarPath = `/cadastro/instituto/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alterarGrupo: async (id: number, grupoAcesso: GrupoAcesso, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('alterarGrupo', 'id', id)
            // verify required parameter 'grupoAcesso' is not null or undefined
            assertParamExists('alterarGrupo', 'grupoAcesso', grupoAcesso)
            const localVarPath = `/cadastro/instituto/alterarGrupo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grupoAcesso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bancario: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/bancario`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscarTipoDeCalculo: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('buscarTipoDeCalculo', 'id', id)
            const localVarPath = `/cadastro/instituto/buscarTipoDeCalculo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadastrarGrupo: async (grupoAcesso: GrupoAcesso, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grupoAcesso' is not null or undefined
            assertParamExists('cadastrarGrupo', 'grupoAcesso', grupoAcesso)
            const localVarPath = `/cadastro/instituto/cadastrarGrupo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grupoAcesso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IndicesReferencia} indicesReferencia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadastrarIndices: async (indicesReferencia: IndicesReferencia, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'indicesReferencia' is not null or undefined
            assertParamExists('cadastrarIndices', 'indicesReferencia', indicesReferencia)
            const localVarPath = `/cadastro/instituto/cadastrarIndices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indicesReferencia, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadastrarLogo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/cadastrarLogo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkList: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkList', 'id', id)
            const localVarPath = `/cadastro/instituto/checkList/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkListContrato: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkListContrato', 'id', id)
            const localVarPath = `/cadastro/instituto/checkListContrato/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkListContratoSelecionado: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('checkListContratoSelecionado', 'id', id)
            const localVarPath = `/cadastro/instituto/checkListContratoSelecionado/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contaBancaria: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/conta-bancaria`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contaBancariaPut: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/conta-bancaria/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBancario: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBancario', 'id', id)
            const localVarPath = `/cadastro/instituto/deleteBancario/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentoCRP: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDocumentoCRP', 'id', id)
            const localVarPath = `/cadastro/instituto/deleteDocumentoCRP/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentoCRP: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadDocumentoCRP', 'id', id)
            const localVarPath = `/cadastro/instituto/downloadDocumentoCRP/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCheckList: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editCheckList', 'id', id)
            const localVarPath = `/cadastro/instituto/editCheckList/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editarGrupo: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editarGrupo', 'id', id)
            const localVarPath = `/cadastro/instituto/editarGrupo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editarGrupoPermissoes: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('editarGrupoPermissoes', 'id', id)
            const localVarPath = `/cadastro/instituto/editarGrupoPermissoes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excluirCheckList: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('excluirCheckList', 'id', id)
            const localVarPath = `/cadastro/instituto/excluirCheckList/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excluirCriteriosElegibilidade: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('excluirCriteriosElegibilidade', 'id', id)
            const localVarPath = `/cadastro/instituto/excluirCriteriosElegibilidade/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excluirDocumento: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('excluirDocumento', 'id', id)
            const localVarPath = `/cadastro/instituto/excluirDocumento/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excluirDocumentoCheckList: async (requestBody: Array<string>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('excluirDocumentoCheckList', 'requestBody', requestBody)
            const localVarPath = `/cadastro/instituto/excluirDocumentoCheckList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excluirGrupo: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('excluirGrupo', 'id', id)
            const localVarPath = `/cadastro/instituto/excluirGrupo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excluirIndice: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('excluirIndice', 'id', id)
            const localVarPath = `/cadastro/instituto/excluirIndice/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excluirRecursos: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('excluirRecursos', 'id', id)
            const localVarPath = `/cadastro/instituto/excluirRecursos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnoIndices: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pesquisar' is not null or undefined
            assertParamExists('getAnoIndices', 'pesquisar', pesquisar)
            const localVarPath = `/cadastro/instituto/GetAnoIndices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBancarioId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBancarioId', 'id', id)
            const localVarPath = `/cadastro/instituto/bancarioID/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBancarioInstId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBancarioInstId', 'id', id)
            const localVarPath = `/cadastro/instituto/bancarioInst/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CriteriosElegibilidadeDto} criteriosElegibilidadeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuscaCepInstituto: async (criteriosElegibilidadeDto: CriteriosElegibilidadeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'criteriosElegibilidadeDto' is not null or undefined
            assertParamExists('getBuscaCepInstituto', 'criteriosElegibilidadeDto', criteriosElegibilidadeDto)
            const localVarPath = `/cadastro/instituto/GetBuscaCepInstituto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(criteriosElegibilidadeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentoCRPId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentoCRPId', 'id', id)
            const localVarPath = `/cadastro/instituto/getDocumentoCRPId{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GrupoAcessoFiltro} grupoAcessoFiltro 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrupo: async (grupoAcessoFiltro: GrupoAcessoFiltro, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grupoAcessoFiltro' is not null or undefined
            assertParamExists('getGrupo', 'grupoAcessoFiltro', grupoAcessoFiltro)
            const localVarPath = `/cadastro/instituto/GetGrupo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grupoAcessoFiltro, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrupoPorId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getGrupoPorId', 'id', id)
            const localVarPath = `/cadastro/instituto/GetGrupoPorId`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GrupoAcessoFiltroInst} grupoAcessoFiltroInst 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrupoPorInst: async (grupoAcessoFiltroInst: GrupoAcessoFiltroInst, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'grupoAcessoFiltroInst' is not null or undefined
            assertParamExists('getGrupoPorInst', 'grupoAcessoFiltroInst', grupoAcessoFiltroInst)
            const localVarPath = `/cadastro/instituto/GetGrupoPorInst`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grupoAcessoFiltroInst, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getId', 'id', id)
            const localVarPath = `/cadastro/instituto/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndiceSelecionado: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/GetIndiceSelecionado`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndices: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getIndices', 'id', id)
            const localVarPath = `/cadastro/instituto/GetIndices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstPorId: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/GetInstPorId`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutoIndice: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/GetInstitutoIndice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPesquisarCriteriosElegibilidade: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/GetPesquisarCriteriosElegibilidade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetPesquisarIdadeCriteriosElegibilidadeDto} getPesquisarIdadeCriteriosElegibilidadeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPesquisarIdadeCriteriosElegibilidade: async (getPesquisarIdadeCriteriosElegibilidadeDto: GetPesquisarIdadeCriteriosElegibilidadeDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getPesquisarIdadeCriteriosElegibilidadeDto' is not null or undefined
            assertParamExists('getPesquisarIdadeCriteriosElegibilidade', 'getPesquisarIdadeCriteriosElegibilidadeDto', getPesquisarIdadeCriteriosElegibilidadeDto)
            const localVarPath = `/cadastro/instituto/GetPesquisarIdadeCriteriosElegibilidade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getPesquisarIdadeCriteriosElegibilidadeDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetTaxasDto} getTaxasDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxas: async (getTaxasDto: GetTaxasDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getTaxasDto' is not null or undefined
            assertParamExists('getTaxas', 'getTaxasDto', getTaxasDto)
            const localVarPath = `/cadastro/instituto/GetTaxas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getTaxasDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} tipoIndice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUltimoIndicesReferencia: async (tipoIndice: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'tipoIndice' is not null or undefined
            assertParamExists('getUltimoIndicesReferencia', 'tipoIndice', tipoIndice)
            const localVarPath = `/cadastro/instituto/GetUltimoIndicesReferencia/{tipoIndice}`
                .replace(`{${"tipoIndice"}}`, encodeURIComponent(String(tipoIndice)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getcarregarParametroDocumento: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/GetcarregarParametroDocumento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getipcaAcumulado: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pesquisar' is not null or undefined
            assertParamExists('getipcaAcumulado', 'pesquisar', pesquisar)
            const localVarPath = `/cadastro/instituto/GetipcaAcumulado`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getpesquisar: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pesquisar' is not null or undefined
            assertParamExists('getpesquisar', 'pesquisar', pesquisar)
            const localVarPath = `/cadastro/instituto/Getpesquisar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getpesquisarBancario: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pesquisar' is not null or undefined
            assertParamExists('getpesquisarBancario', 'pesquisar', pesquisar)
            const localVarPath = `/cadastro/instituto/GetpesquisarBancario`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getpesquisarGrupo: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pesquisar' is not null or undefined
            assertParamExists('getpesquisarGrupo', 'pesquisar', pesquisar)
            const localVarPath = `/cadastro/instituto/GetpesquisarGrupo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getpesquisarGrupoInstID: async (id: number, pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getpesquisarGrupoInstID', 'id', id)
            // verify required parameter 'pesquisar' is not null or undefined
            assertParamExists('getpesquisarGrupoInstID', 'pesquisar', pesquisar)
            const localVarPath = `/cadastro/instituto/GetpesquisarGrupoInstID/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getpesquisarIndices: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/GetpesquisarIndices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getpesquisarRecursos: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/GetpesquisarRecursos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        historicoTaxas: async (body: object, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'body' is not null or undefined
            assertParamExists('historicoTaxas', 'body', body)
            const localVarPath = `/cadastro/instituto/GetHistoricoTaxas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        historicoTaxasPosFixado: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/GetHistoricoTaxasPosFixado`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        limparLogo: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('limparLogo', 'id', id)
            const localVarPath = `/cadastro/instituto/limparLogo/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: async (instituto: Instituto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'instituto' is not null or undefined
            assertParamExists('login', 'instituto', instituto)
            const localVarPath = `/cadastro/instituto`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instituto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        novoCRPDocumento: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/novoCRPDocumento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        novoDocumento: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/novoDocumento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametro: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('parametro', 'id', id)
            const localVarPath = `/cadastro/instituto/parametroCheckList/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCheckList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/postCheckList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentoCheckListType} documentoCheckListType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDocumentoCheckList: async (documentoCheckListType: DocumentoCheckListType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoCheckListType' is not null or undefined
            assertParamExists('postDocumentoCheckList', 'documentoCheckListType', documentoCheckListType)
            const localVarPath = `/cadastro/instituto/postDocumentoCheckList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentoCheckListType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        put: async (id: number, instituto: Instituto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('put', 'id', id)
            // verify required parameter 'instituto' is not null or undefined
            assertParamExists('put', 'instituto', instituto)
            const localVarPath = `/cadastro/instituto/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instituto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBancario: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putBancario', 'id', id)
            const localVarPath = `/cadastro/instituto/updateBancario/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCheckList: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putCheckList', 'id', id)
            const localVarPath = `/cadastro/instituto/putCheckList/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGrupoCadastro: async (id: number, grupoAcesso: GrupoAcesso, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putGrupoCadastro', 'id', id)
            // verify required parameter 'grupoAcesso' is not null or undefined
            assertParamExists('putGrupoCadastro', 'grupoAcesso', grupoAcesso)
            const localVarPath = `/cadastro/instituto/putGrupoCadastro/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grupoAcesso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGrupoConfiguracoes: async (id: number, grupoAcesso: GrupoAcesso, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putGrupoConfiguracoes', 'id', id)
            // verify required parameter 'grupoAcesso' is not null or undefined
            assertParamExists('putGrupoConfiguracoes', 'grupoAcesso', grupoAcesso)
            const localVarPath = `/cadastro/instituto/putGrupoConfiguracoes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grupoAcesso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGrupoFinanceiro: async (id: number, grupoAcesso: GrupoAcesso, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putGrupoFinanceiro', 'id', id)
            // verify required parameter 'grupoAcesso' is not null or undefined
            assertParamExists('putGrupoFinanceiro', 'grupoAcesso', grupoAcesso)
            const localVarPath = `/cadastro/instituto/putGrupoFinanceiro/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grupoAcesso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGrupoIeE: async (id: number, grupoAcesso: GrupoAcesso, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putGrupoIeE', 'id', id)
            // verify required parameter 'grupoAcesso' is not null or undefined
            assertParamExists('putGrupoIeE', 'grupoAcesso', grupoAcesso)
            const localVarPath = `/cadastro/instituto/putGrupoIeE/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grupoAcesso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGrupoProposta: async (id: number, grupoAcesso: GrupoAcesso, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putGrupoProposta', 'id', id)
            // verify required parameter 'grupoAcesso' is not null or undefined
            assertParamExists('putGrupoProposta', 'grupoAcesso', grupoAcesso)
            const localVarPath = `/cadastro/instituto/putGrupoProposta/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grupoAcesso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGrupoRelatorio: async (id: number, grupoAcesso: GrupoAcesso, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putGrupoRelatorio', 'id', id)
            // verify required parameter 'grupoAcesso' is not null or undefined
            assertParamExists('putGrupoRelatorio', 'grupoAcesso', grupoAcesso)
            const localVarPath = `/cadastro/instituto/putGrupoRelatorio/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grupoAcesso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGrupoSimulador: async (id: number, grupoAcesso: GrupoAcesso, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putGrupoSimulador', 'id', id)
            // verify required parameter 'grupoAcesso' is not null or undefined
            assertParamExists('putGrupoSimulador', 'grupoAcesso', grupoAcesso)
            const localVarPath = `/cadastro/instituto/putGrupoSimulador/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(grupoAcesso, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {IndicesReferencia} indicesReferencia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIndices: async (id: number, indicesReferencia: IndicesReferencia, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putIndices', 'id', id)
            // verify required parameter 'indicesReferencia' is not null or undefined
            assertParamExists('putIndices', 'indicesReferencia', indicesReferencia)
            const localVarPath = `/cadastro/instituto/putIndices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(indicesReferencia, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParametroBoleto: async (id: number, instituto: Instituto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putParametroBoleto', 'id', id)
            // verify required parameter 'instituto' is not null or undefined
            assertParamExists('putParametroBoleto', 'instituto', instituto)
            const localVarPath = `/cadastro/instituto/parametroBoleto/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instituto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParametroGrpe: async (id: number, instituto: Instituto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putParametroGrpe', 'id', id)
            // verify required parameter 'instituto' is not null or undefined
            assertParamExists('putParametroGrpe', 'instituto', instituto)
            const localVarPath = `/cadastro/instituto/parametroGrpe/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instituto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParametroRenegociacao: async (id: number, instituto: Instituto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putParametroRenegociacao', 'id', id)
            // verify required parameter 'instituto' is not null or undefined
            assertParamExists('putParametroRenegociacao', 'instituto', instituto)
            const localVarPath = `/cadastro/instituto/parametroRenegociacao/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instituto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPersonalizacoes: async (id: number, instituto: Instituto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putPersonalizacoes', 'id', id)
            // verify required parameter 'instituto' is not null or undefined
            assertParamExists('putPersonalizacoes', 'instituto', instituto)
            const localVarPath = `/cadastro/instituto/personalizacoes/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instituto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {PutRecursos} putRecursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRecursos: async (id: number, putRecursos: PutRecursos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putRecursos', 'id', id)
            // verify required parameter 'putRecursos' is not null or undefined
            assertParamExists('putRecursos', 'putRecursos', putRecursos)
            const localVarPath = `/cadastro/instituto/putRecursos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(putRecursos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTaxas: async (id: number, instituto: Instituto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putTaxas', 'id', id)
            // verify required parameter 'instituto' is not null or undefined
            assertParamExists('putTaxas', 'instituto', instituto)
            const localVarPath = `/cadastro/instituto/taxas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instituto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTaxasPosFixado: async (id: number, instituto: Instituto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putTaxasPosFixado', 'id', id)
            // verify required parameter 'instituto' is not null or undefined
            assertParamExists('putTaxasPosFixado', 'instituto', instituto)
            const localVarPath = `/cadastro/instituto/taxasPosFixado/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instituto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrarCriteriosElegibilidade: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/registrarCriteriosElegibilidade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PostRegistrarRecursos} postRegistrarRecursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrarRecursos: async (postRegistrarRecursos: PostRegistrarRecursos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'postRegistrarRecursos' is not null or undefined
            assertParamExists('registrarRecursos', 'postRegistrarRecursos', postRegistrarRecursos)
            const localVarPath = `/cadastro/instituto/registrarRecursos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postRegistrarRecursos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentoCheckListSalvarType} documentoCheckListSalvarType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvarParametroDocumento: async (documentoCheckListSalvarType: DocumentoCheckListSalvarType, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentoCheckListSalvarType' is not null or undefined
            assertParamExists('salvarParametroDocumento', 'documentoCheckListSalvarType', documentoCheckListSalvarType)
            const localVarPath = `/cadastro/instituto/salvarParametroDocumento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentoCheckListSalvarType, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setImportarTaxas: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/SetImportarTaxas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PesquisaIndice} pesquisaIndice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInstitutoIndice: async (pesquisaIndice: PesquisaIndice, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pesquisaIndice' is not null or undefined
            assertParamExists('setInstitutoIndice', 'pesquisaIndice', pesquisaIndice)
            const localVarPath = `/cadastro/instituto/SetInstitutoIndice`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pesquisaIndice, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCriteriosElegibilidade: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/updateCriteriosElegibilidade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumento: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateDocumento', 'id', id)
            const localVarPath = `/cadastro/instituto/updateDocumento/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/cadastro/instituto/uploadDocumentoCRP`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('user', 'id', id)
            const localVarPath = `/cadastro/instituto/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstitutoApi - functional programming interface
 * @export
 */
export const InstitutoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstitutoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi._delete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async alterarGrupo(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.alterarGrupo(id, grupoAcesso, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.alterarGrupo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bancario(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bancario(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.bancario']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async buscarTipoDeCalculo(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.buscarTipoDeCalculo(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.buscarTipoDeCalculo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cadastrarGrupo(grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cadastrarGrupo(grupoAcesso, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.cadastrarGrupo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {IndicesReferencia} indicesReferencia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cadastrarIndices(indicesReferencia: IndicesReferencia, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cadastrarIndices(indicesReferencia, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.cadastrarIndices']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cadastrarLogo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cadastrarLogo(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.cadastrarLogo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkList(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkList(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.checkList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkListContrato(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkListContrato(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.checkListContrato']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkListContratoSelecionado(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkListContratoSelecionado(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.checkListContratoSelecionado']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contaBancaria(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contaBancaria(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.contaBancaria']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async contaBancariaPut(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.contaBancariaPut(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.contaBancariaPut']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBancario(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBancario(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.deleteBancario']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumentoCRP(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumentoCRP(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.deleteDocumentoCRP']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadDocumentoCRP(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadDocumentoCRP(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.downloadDocumentoCRP']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editCheckList(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editCheckList(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.editCheckList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editarGrupo(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editarGrupo(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.editarGrupo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async editarGrupoPermissoes(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.editarGrupoPermissoes(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.editarGrupoPermissoes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excluirCheckList(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excluirCheckList(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.excluirCheckList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excluirCriteriosElegibilidade(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excluirCriteriosElegibilidade(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.excluirCriteriosElegibilidade']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excluirDocumento(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excluirDocumento(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.excluirDocumento']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excluirDocumentoCheckList(requestBody: Array<string>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excluirDocumentoCheckList(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.excluirDocumentoCheckList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excluirGrupo(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excluirGrupo(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.excluirGrupo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excluirIndice(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excluirIndice(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.excluirIndice']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async excluirRecursos(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.excluirRecursos(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.excluirRecursos']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAll(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAll(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getAll']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnoIndices(pesquisar: Pesquisar, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnoIndices(pesquisar, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getAnoIndices']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBancarioId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBancarioId(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getBancarioId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBancarioInstId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBancarioInstId(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getBancarioInstId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {CriteriosElegibilidadeDto} criteriosElegibilidadeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBuscaCepInstituto(criteriosElegibilidadeDto: CriteriosElegibilidadeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBuscaCepInstituto(criteriosElegibilidadeDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getBuscaCepInstituto']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentoCRPId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentoCRPId(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getDocumentoCRPId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GrupoAcessoFiltro} grupoAcessoFiltro 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGrupo(grupoAcessoFiltro: GrupoAcessoFiltro, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGrupo(grupoAcessoFiltro, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getGrupo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGrupoPorId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGrupoPorId(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getGrupoPorId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GrupoAcessoFiltroInst} grupoAcessoFiltroInst 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGrupoPorInst(grupoAcessoFiltroInst: GrupoAcessoFiltroInst, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGrupoPorInst(grupoAcessoFiltroInst, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getGrupoPorInst']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getId(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndiceSelecionado(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndiceSelecionado(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getIndiceSelecionado']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndices(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndices(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getIndices']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstPorId(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstPorId(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getInstPorId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstitutoIndice(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstitutoIndice(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getInstitutoIndice']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPesquisarCriteriosElegibilidade(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPesquisarCriteriosElegibilidade(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getPesquisarCriteriosElegibilidade']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GetPesquisarIdadeCriteriosElegibilidadeDto} getPesquisarIdadeCriteriosElegibilidadeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPesquisarIdadeCriteriosElegibilidade(getPesquisarIdadeCriteriosElegibilidadeDto: GetPesquisarIdadeCriteriosElegibilidadeDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPesquisarIdadeCriteriosElegibilidade(getPesquisarIdadeCriteriosElegibilidadeDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getPesquisarIdadeCriteriosElegibilidade']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GetTaxasDto} getTaxasDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaxas(getTaxasDto: GetTaxasDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IndicesReferencia>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaxas(getTaxasDto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getTaxas']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} tipoIndice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUltimoIndicesReferencia(tipoIndice: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUltimoIndicesReferencia(tipoIndice, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getUltimoIndicesReferencia']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getcarregarParametroDocumento(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getcarregarParametroDocumento(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getcarregarParametroDocumento']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getipcaAcumulado(pesquisar: Pesquisar, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getipcaAcumulado(pesquisar, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getipcaAcumulado']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getpesquisar(pesquisar: Pesquisar, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getpesquisar(pesquisar, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getpesquisar']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getpesquisarBancario(pesquisar: Pesquisar, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getpesquisarBancario(pesquisar, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getpesquisarBancario']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getpesquisarGrupo(pesquisar: Pesquisar, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getpesquisarGrupo(pesquisar, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getpesquisarGrupo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getpesquisarGrupoInstID(id: number, pesquisar: Pesquisar, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getpesquisarGrupoInstID(id, pesquisar, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getpesquisarGrupoInstID']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getpesquisarIndices(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getpesquisarIndices(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getpesquisarIndices']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getpesquisarRecursos(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getpesquisarRecursos(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.getpesquisarRecursos']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async historicoTaxas(body: object, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComposicaoTaxaHistorico>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.historicoTaxas(body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.historicoTaxas']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async historicoTaxasPosFixado(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.historicoTaxasPosFixado(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.historicoTaxasPosFixado']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async limparLogo(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.limparLogo(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.limparLogo']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async login(instituto: Instituto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.login(instituto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.login']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async novoCRPDocumento(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.novoCRPDocumento(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.novoCRPDocumento']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async novoDocumento(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.novoDocumento(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.novoDocumento']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async parametro(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.parametro(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.parametro']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCheckList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCheckList(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.postCheckList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {DocumentoCheckListType} documentoCheckListType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDocumentoCheckList(documentoCheckListType: DocumentoCheckListType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDocumentoCheckList(documentoCheckListType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.postDocumentoCheckList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async put(id: number, instituto: Instituto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.put(id, instituto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.put']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putBancario(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putBancario(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putBancario']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCheckList(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCheckList(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putCheckList']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putGrupoCadastro(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GrupoAcesso>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putGrupoCadastro(id, grupoAcesso, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putGrupoCadastro']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putGrupoConfiguracoes(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putGrupoConfiguracoes(id, grupoAcesso, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putGrupoConfiguracoes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putGrupoFinanceiro(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putGrupoFinanceiro(id, grupoAcesso, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putGrupoFinanceiro']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putGrupoIeE(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putGrupoIeE(id, grupoAcesso, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putGrupoIeE']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putGrupoProposta(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putGrupoProposta(id, grupoAcesso, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putGrupoProposta']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putGrupoRelatorio(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putGrupoRelatorio(id, grupoAcesso, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putGrupoRelatorio']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putGrupoSimulador(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putGrupoSimulador(id, grupoAcesso, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putGrupoSimulador']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {IndicesReferencia} indicesReferencia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putIndices(id: number, indicesReferencia: IndicesReferencia, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putIndices(id, indicesReferencia, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putIndices']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putParametroBoleto(id: number, instituto: Instituto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instituto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putParametroBoleto(id, instituto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putParametroBoleto']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putParametroGrpe(id: number, instituto: Instituto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instituto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putParametroGrpe(id, instituto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putParametroGrpe']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putParametroRenegociacao(id: number, instituto: Instituto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instituto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putParametroRenegociacao(id, instituto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putParametroRenegociacao']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putPersonalizacoes(id: number, instituto: Instituto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instituto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putPersonalizacoes(id, instituto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putPersonalizacoes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {PutRecursos} putRecursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putRecursos(id: number, putRecursos: PutRecursos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Recursos>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putRecursos(id, putRecursos, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putRecursos']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTaxas(id: number, instituto: Instituto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instituto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTaxas(id, instituto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putTaxas']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putTaxasPosFixado(id: number, instituto: Instituto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instituto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putTaxasPosFixado(id, instituto, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.putTaxasPosFixado']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registrarCriteriosElegibilidade(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registrarCriteriosElegibilidade(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.registrarCriteriosElegibilidade']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {PostRegistrarRecursos} postRegistrarRecursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registrarRecursos(postRegistrarRecursos: PostRegistrarRecursos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registrarRecursos(postRegistrarRecursos, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.registrarRecursos']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {DocumentoCheckListSalvarType} documentoCheckListSalvarType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async salvarParametroDocumento(documentoCheckListSalvarType: DocumentoCheckListSalvarType, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.salvarParametroDocumento(documentoCheckListSalvarType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.salvarParametroDocumento']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setImportarTaxas(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setImportarTaxas(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.setImportarTaxas']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {PesquisaIndice} pesquisaIndice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setInstitutoIndice(pesquisaIndice: PesquisaIndice, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setInstitutoIndice(pesquisaIndice, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.setInstitutoIndice']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateCriteriosElegibilidade(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateCriteriosElegibilidade(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.updateCriteriosElegibilidade']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDocumento(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDocumento(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.updateDocumento']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.uploadFile']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async user(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.user(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['InstitutoApi.user']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * InstitutoApi - factory interface
 * @export
 */
export const InstitutoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstitutoApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: number, options?: any): AxiosPromise<void> {
            return localVarFp._delete(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        alterarGrupo(id: number, grupoAcesso: GrupoAcesso, options?: any): AxiosPromise<void> {
            return localVarFp.alterarGrupo(id, grupoAcesso, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bancario(options?: any): AxiosPromise<void> {
            return localVarFp.bancario(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buscarTipoDeCalculo(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.buscarTipoDeCalculo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadastrarGrupo(grupoAcesso: GrupoAcesso, options?: any): AxiosPromise<void> {
            return localVarFp.cadastrarGrupo(grupoAcesso, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IndicesReferencia} indicesReferencia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadastrarIndices(indicesReferencia: IndicesReferencia, options?: any): AxiosPromise<void> {
            return localVarFp.cadastrarIndices(indicesReferencia, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cadastrarLogo(options?: any): AxiosPromise<void> {
            return localVarFp.cadastrarLogo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkList(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.checkList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkListContrato(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.checkListContrato(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkListContratoSelecionado(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.checkListContratoSelecionado(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contaBancaria(options?: any): AxiosPromise<void> {
            return localVarFp.contaBancaria(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contaBancariaPut(options?: any): AxiosPromise<void> {
            return localVarFp.contaBancariaPut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBancario(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBancario(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumentoCRP(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDocumentoCRP(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadDocumentoCRP(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.downloadDocumentoCRP(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCheckList(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.editCheckList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editarGrupo(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.editarGrupo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editarGrupoPermissoes(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.editarGrupoPermissoes(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excluirCheckList(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.excluirCheckList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excluirCriteriosElegibilidade(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.excluirCriteriosElegibilidade(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excluirDocumento(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.excluirDocumento(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excluirDocumentoCheckList(requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.excluirDocumentoCheckList(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excluirGrupo(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.excluirGrupo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excluirIndice(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.excluirIndice(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        excluirRecursos(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.excluirRecursos(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAll(options?: any): AxiosPromise<void> {
            return localVarFp.getAll(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnoIndices(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
            return localVarFp.getAnoIndices(pesquisar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBancarioId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getBancarioId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBancarioInstId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getBancarioInstId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CriteriosElegibilidadeDto} criteriosElegibilidadeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBuscaCepInstituto(criteriosElegibilidadeDto: CriteriosElegibilidadeDto, options?: any): AxiosPromise<void> {
            return localVarFp.getBuscaCepInstituto(criteriosElegibilidadeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentoCRPId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getDocumentoCRPId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GrupoAcessoFiltro} grupoAcessoFiltro 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrupo(grupoAcessoFiltro: GrupoAcessoFiltro, options?: any): AxiosPromise<void> {
            return localVarFp.getGrupo(grupoAcessoFiltro, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrupoPorId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getGrupoPorId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GrupoAcessoFiltroInst} grupoAcessoFiltroInst 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrupoPorInst(grupoAcessoFiltroInst: GrupoAcessoFiltroInst, options?: any): AxiosPromise<void> {
            return localVarFp.getGrupoPorInst(grupoAcessoFiltroInst, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndiceSelecionado(options?: any): AxiosPromise<void> {
            return localVarFp.getIndiceSelecionado(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndices(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getIndices(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstPorId(options?: any): AxiosPromise<void> {
            return localVarFp.getInstPorId(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstitutoIndice(options?: any): AxiosPromise<void> {
            return localVarFp.getInstitutoIndice(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPesquisarCriteriosElegibilidade(options?: any): AxiosPromise<void> {
            return localVarFp.getPesquisarCriteriosElegibilidade(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetPesquisarIdadeCriteriosElegibilidadeDto} getPesquisarIdadeCriteriosElegibilidadeDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPesquisarIdadeCriteriosElegibilidade(getPesquisarIdadeCriteriosElegibilidadeDto: GetPesquisarIdadeCriteriosElegibilidadeDto, options?: any): AxiosPromise<void> {
            return localVarFp.getPesquisarIdadeCriteriosElegibilidade(getPesquisarIdadeCriteriosElegibilidadeDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetTaxasDto} getTaxasDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxas(getTaxasDto: GetTaxasDto, options?: any): AxiosPromise<Array<IndicesReferencia>> {
            return localVarFp.getTaxas(getTaxasDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} tipoIndice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUltimoIndicesReferencia(tipoIndice: string, options?: any): AxiosPromise<void> {
            return localVarFp.getUltimoIndicesReferencia(tipoIndice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getcarregarParametroDocumento(options?: any): AxiosPromise<void> {
            return localVarFp.getcarregarParametroDocumento(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getipcaAcumulado(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
            return localVarFp.getipcaAcumulado(pesquisar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getpesquisar(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
            return localVarFp.getpesquisar(pesquisar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getpesquisarBancario(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
            return localVarFp.getpesquisarBancario(pesquisar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getpesquisarGrupo(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
            return localVarFp.getpesquisarGrupo(pesquisar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getpesquisarGrupoInstID(id: number, pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
            return localVarFp.getpesquisarGrupoInstID(id, pesquisar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getpesquisarIndices(options?: any): AxiosPromise<void> {
            return localVarFp.getpesquisarIndices(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getpesquisarRecursos(options?: any): AxiosPromise<void> {
            return localVarFp.getpesquisarRecursos(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        historicoTaxas(body: object, options?: any): AxiosPromise<Array<ComposicaoTaxaHistorico>> {
            return localVarFp.historicoTaxas(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        historicoTaxasPosFixado(options?: any): AxiosPromise<void> {
            return localVarFp.historicoTaxasPosFixado(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        limparLogo(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.limparLogo(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(instituto: Instituto, options?: any): AxiosPromise<void> {
            return localVarFp.login(instituto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        novoCRPDocumento(options?: any): AxiosPromise<void> {
            return localVarFp.novoCRPDocumento(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        novoDocumento(options?: any): AxiosPromise<void> {
            return localVarFp.novoDocumento(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        parametro(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.parametro(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCheckList(options?: any): AxiosPromise<void> {
            return localVarFp.postCheckList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentoCheckListType} documentoCheckListType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDocumentoCheckList(documentoCheckListType: DocumentoCheckListType, options?: any): AxiosPromise<void> {
            return localVarFp.postDocumentoCheckList(documentoCheckListType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        put(id: number, instituto: Instituto, options?: any): AxiosPromise<void> {
            return localVarFp.put(id, instituto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putBancario(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.putBancario(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCheckList(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.putCheckList(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGrupoCadastro(id: number, grupoAcesso: GrupoAcesso, options?: any): AxiosPromise<GrupoAcesso> {
            return localVarFp.putGrupoCadastro(id, grupoAcesso, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGrupoConfiguracoes(id: number, grupoAcesso: GrupoAcesso, options?: any): AxiosPromise<void> {
            return localVarFp.putGrupoConfiguracoes(id, grupoAcesso, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGrupoFinanceiro(id: number, grupoAcesso: GrupoAcesso, options?: any): AxiosPromise<void> {
            return localVarFp.putGrupoFinanceiro(id, grupoAcesso, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGrupoIeE(id: number, grupoAcesso: GrupoAcesso, options?: any): AxiosPromise<void> {
            return localVarFp.putGrupoIeE(id, grupoAcesso, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGrupoProposta(id: number, grupoAcesso: GrupoAcesso, options?: any): AxiosPromise<void> {
            return localVarFp.putGrupoProposta(id, grupoAcesso, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGrupoRelatorio(id: number, grupoAcesso: GrupoAcesso, options?: any): AxiosPromise<void> {
            return localVarFp.putGrupoRelatorio(id, grupoAcesso, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {GrupoAcesso} grupoAcesso 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putGrupoSimulador(id: number, grupoAcesso: GrupoAcesso, options?: any): AxiosPromise<void> {
            return localVarFp.putGrupoSimulador(id, grupoAcesso, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {IndicesReferencia} indicesReferencia 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putIndices(id: number, indicesReferencia: IndicesReferencia, options?: any): AxiosPromise<void> {
            return localVarFp.putIndices(id, indicesReferencia, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParametroBoleto(id: number, instituto: Instituto, options?: any): AxiosPromise<Instituto> {
            return localVarFp.putParametroBoleto(id, instituto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParametroGrpe(id: number, instituto: Instituto, options?: any): AxiosPromise<Instituto> {
            return localVarFp.putParametroGrpe(id, instituto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putParametroRenegociacao(id: number, instituto: Instituto, options?: any): AxiosPromise<Instituto> {
            return localVarFp.putParametroRenegociacao(id, instituto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putPersonalizacoes(id: number, instituto: Instituto, options?: any): AxiosPromise<Instituto> {
            return localVarFp.putPersonalizacoes(id, instituto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {PutRecursos} putRecursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRecursos(id: number, putRecursos: PutRecursos, options?: any): AxiosPromise<Recursos> {
            return localVarFp.putRecursos(id, putRecursos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTaxas(id: number, instituto: Instituto, options?: any): AxiosPromise<Instituto> {
            return localVarFp.putTaxas(id, instituto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {Instituto} instituto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putTaxasPosFixado(id: number, instituto: Instituto, options?: any): AxiosPromise<Instituto> {
            return localVarFp.putTaxasPosFixado(id, instituto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrarCriteriosElegibilidade(options?: any): AxiosPromise<void> {
            return localVarFp.registrarCriteriosElegibilidade(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PostRegistrarRecursos} postRegistrarRecursos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registrarRecursos(postRegistrarRecursos: PostRegistrarRecursos, options?: any): AxiosPromise<void> {
            return localVarFp.registrarRecursos(postRegistrarRecursos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentoCheckListSalvarType} documentoCheckListSalvarType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        salvarParametroDocumento(documentoCheckListSalvarType: DocumentoCheckListSalvarType, options?: any): AxiosPromise<void> {
            return localVarFp.salvarParametroDocumento(documentoCheckListSalvarType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setImportarTaxas(options?: any): AxiosPromise<void> {
            return localVarFp.setImportarTaxas(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PesquisaIndice} pesquisaIndice 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInstitutoIndice(pesquisaIndice: PesquisaIndice, options?: any): AxiosPromise<void> {
            return localVarFp.setInstitutoIndice(pesquisaIndice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCriteriosElegibilidade(options?: any): AxiosPromise<void> {
            return localVarFp.updateCriteriosElegibilidade(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDocumento(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.updateDocumento(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(options?: any): AxiosPromise<void> {
            return localVarFp.uploadFile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        user(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.user(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstitutoApi - object-oriented interface
 * @export
 * @class InstitutoApi
 * @extends {BaseAPI}
 */
export class InstitutoApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public _delete(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration)._delete(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {GrupoAcesso} grupoAcesso 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public alterarGrupo(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).alterarGrupo(id, grupoAcesso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public bancario(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).bancario(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public buscarTipoDeCalculo(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).buscarTipoDeCalculo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GrupoAcesso} grupoAcesso 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public cadastrarGrupo(grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).cadastrarGrupo(grupoAcesso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IndicesReferencia} indicesReferencia 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public cadastrarIndices(indicesReferencia: IndicesReferencia, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).cadastrarIndices(indicesReferencia, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public cadastrarLogo(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).cadastrarLogo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public checkList(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).checkList(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public checkListContrato(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).checkListContrato(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public checkListContratoSelecionado(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).checkListContratoSelecionado(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public contaBancaria(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).contaBancaria(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public contaBancariaPut(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).contaBancariaPut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public deleteBancario(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).deleteBancario(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public deleteDocumentoCRP(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).deleteDocumentoCRP(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public downloadDocumentoCRP(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).downloadDocumentoCRP(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public editCheckList(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).editCheckList(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public editarGrupo(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).editarGrupo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public editarGrupoPermissoes(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).editarGrupoPermissoes(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public excluirCheckList(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).excluirCheckList(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public excluirCriteriosElegibilidade(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).excluirCriteriosElegibilidade(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public excluirDocumento(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).excluirDocumento(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public excluirDocumentoCheckList(requestBody: Array<string>, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).excluirDocumentoCheckList(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public excluirGrupo(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).excluirGrupo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public excluirIndice(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).excluirIndice(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public excluirRecursos(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).excluirRecursos(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getAll(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getAll(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pesquisar} pesquisar 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getAnoIndices(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getAnoIndices(pesquisar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getBancarioId(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getBancarioId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getBancarioInstId(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getBancarioInstId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CriteriosElegibilidadeDto} criteriosElegibilidadeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getBuscaCepInstituto(criteriosElegibilidadeDto: CriteriosElegibilidadeDto, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getBuscaCepInstituto(criteriosElegibilidadeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getDocumentoCRPId(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getDocumentoCRPId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GrupoAcessoFiltro} grupoAcessoFiltro 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getGrupo(grupoAcessoFiltro: GrupoAcessoFiltro, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getGrupo(grupoAcessoFiltro, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getGrupoPorId(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getGrupoPorId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GrupoAcessoFiltroInst} grupoAcessoFiltroInst 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getGrupoPorInst(grupoAcessoFiltroInst: GrupoAcessoFiltroInst, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getGrupoPorInst(grupoAcessoFiltroInst, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getId(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getIndiceSelecionado(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getIndiceSelecionado(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getIndices(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getIndices(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getInstPorId(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getInstPorId(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getInstitutoIndice(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getInstitutoIndice(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getPesquisarCriteriosElegibilidade(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getPesquisarCriteriosElegibilidade(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetPesquisarIdadeCriteriosElegibilidadeDto} getPesquisarIdadeCriteriosElegibilidadeDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getPesquisarIdadeCriteriosElegibilidade(getPesquisarIdadeCriteriosElegibilidadeDto: GetPesquisarIdadeCriteriosElegibilidadeDto, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getPesquisarIdadeCriteriosElegibilidade(getPesquisarIdadeCriteriosElegibilidadeDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetTaxasDto} getTaxasDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getTaxas(getTaxasDto: GetTaxasDto, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getTaxas(getTaxasDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} tipoIndice 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getUltimoIndicesReferencia(tipoIndice: string, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getUltimoIndicesReferencia(tipoIndice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getcarregarParametroDocumento(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getcarregarParametroDocumento(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pesquisar} pesquisar 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getipcaAcumulado(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getipcaAcumulado(pesquisar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pesquisar} pesquisar 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getpesquisar(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getpesquisar(pesquisar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pesquisar} pesquisar 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getpesquisarBancario(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getpesquisarBancario(pesquisar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pesquisar} pesquisar 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getpesquisarGrupo(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getpesquisarGrupo(pesquisar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Pesquisar} pesquisar 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getpesquisarGrupoInstID(id: number, pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getpesquisarGrupoInstID(id, pesquisar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getpesquisarIndices(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getpesquisarIndices(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public getpesquisarRecursos(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).getpesquisarRecursos(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public historicoTaxas(body: object, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).historicoTaxas(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public historicoTaxasPosFixado(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).historicoTaxasPosFixado(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public limparLogo(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).limparLogo(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Instituto} instituto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public login(instituto: Instituto, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).login(instituto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public novoCRPDocumento(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).novoCRPDocumento(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public novoDocumento(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).novoDocumento(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public parametro(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).parametro(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public postCheckList(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).postCheckList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentoCheckListType} documentoCheckListType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public postDocumentoCheckList(documentoCheckListType: DocumentoCheckListType, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).postDocumentoCheckList(documentoCheckListType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Instituto} instituto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public put(id: number, instituto: Instituto, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).put(id, instituto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putBancario(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putBancario(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putCheckList(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putCheckList(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {GrupoAcesso} grupoAcesso 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putGrupoCadastro(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putGrupoCadastro(id, grupoAcesso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {GrupoAcesso} grupoAcesso 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putGrupoConfiguracoes(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putGrupoConfiguracoes(id, grupoAcesso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {GrupoAcesso} grupoAcesso 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putGrupoFinanceiro(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putGrupoFinanceiro(id, grupoAcesso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {GrupoAcesso} grupoAcesso 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putGrupoIeE(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putGrupoIeE(id, grupoAcesso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {GrupoAcesso} grupoAcesso 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putGrupoProposta(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putGrupoProposta(id, grupoAcesso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {GrupoAcesso} grupoAcesso 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putGrupoRelatorio(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putGrupoRelatorio(id, grupoAcesso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {GrupoAcesso} grupoAcesso 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putGrupoSimulador(id: number, grupoAcesso: GrupoAcesso, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putGrupoSimulador(id, grupoAcesso, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {IndicesReferencia} indicesReferencia 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putIndices(id: number, indicesReferencia: IndicesReferencia, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putIndices(id, indicesReferencia, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Instituto} instituto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putParametroBoleto(id: number, instituto: Instituto, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putParametroBoleto(id, instituto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Instituto} instituto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putParametroGrpe(id: number, instituto: Instituto, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putParametroGrpe(id, instituto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Instituto} instituto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putParametroRenegociacao(id: number, instituto: Instituto, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putParametroRenegociacao(id, instituto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Instituto} instituto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putPersonalizacoes(id: number, instituto: Instituto, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putPersonalizacoes(id, instituto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {PutRecursos} putRecursos 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putRecursos(id: number, putRecursos: PutRecursos, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putRecursos(id, putRecursos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Instituto} instituto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putTaxas(id: number, instituto: Instituto, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putTaxas(id, instituto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {Instituto} instituto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public putTaxasPosFixado(id: number, instituto: Instituto, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).putTaxasPosFixado(id, instituto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public registrarCriteriosElegibilidade(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).registrarCriteriosElegibilidade(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PostRegistrarRecursos} postRegistrarRecursos 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public registrarRecursos(postRegistrarRecursos: PostRegistrarRecursos, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).registrarRecursos(postRegistrarRecursos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentoCheckListSalvarType} documentoCheckListSalvarType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public salvarParametroDocumento(documentoCheckListSalvarType: DocumentoCheckListSalvarType, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).salvarParametroDocumento(documentoCheckListSalvarType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public setImportarTaxas(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).setImportarTaxas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PesquisaIndice} pesquisaIndice 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public setInstitutoIndice(pesquisaIndice: PesquisaIndice, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).setInstitutoIndice(pesquisaIndice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public updateCriteriosElegibilidade(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).updateCriteriosElegibilidade(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public updateDocumento(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).updateDocumento(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public uploadFile(options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).uploadFile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstitutoApi
     */
    public user(id: number, options?: RawAxiosRequestConfig) {
        return InstitutoApiFp(this.configuration).user(id, options).then((request) => request(this.axios, this.basePath));
    }
}

