/* tslint:disable */
/* eslint-disable */
/**
 * Consigprev API
 * API do sistema de consignações previdenciárias - ConsigPrev
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import { DocumentacaoProcessoVirtual } from '../models';
// @ts-ignore
import { FinanceiroCompetenciaParcelas } from '../models';
// @ts-ignore
import { GeraGRPE } from '../models';
// @ts-ignore
import { GetIndicadoresContratos } from '../models';
// @ts-ignore
import { Pesquisar } from '../models';
/**
 * FinanceiroApi - axios parameter creator
 * @export
 */
export const FinanceiroApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carregarProcessoVirtual: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('carregarProcessoVirtual', 'id', id)
            const localVarPath = `/financeiro/carregarProcessoVirtual/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaDeParcelasPendentes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financeiro/ConsultaDeParcelasPendentes/{id}`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        criaRemessaBancaria: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financeiro/CriaRemessaBancaria`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumento: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDocumento', 'id', id)
            const localVarPath = `/financeiro/documento/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentacaoProcessoVirtual} documentacaoProcessoVirtual 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentacaoProcesso: async (documentacaoProcessoVirtual: DocumentacaoProcessoVirtual, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentacaoProcessoVirtual' is not null or undefined
            assertParamExists('documentacaoProcesso', 'documentacaoProcessoVirtual', documentacaoProcessoVirtual)
            const localVarPath = `/financeiro/documentacaoProcesso`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentacaoProcessoVirtual, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DocumentacaoProcessoVirtual} documentacaoProcessoVirtual 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentacaoProcessoPdf: async (documentacaoProcessoVirtual: DocumentacaoProcessoVirtual, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'documentacaoProcessoVirtual' is not null or undefined
            assertParamExists('documentacaoProcessoPdf', 'documentacaoProcessoVirtual', documentacaoProcessoVirtual)
            const localVarPath = `/financeiro/documentacaoProcessoPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(documentacaoProcessoVirtual, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('download', 'id', id)
            const localVarPath = `/financeiro/download/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContratoPelaProposta: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financeiro/GetContratoPelaProposta`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEndpoint: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financeiro/aws/files/url`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinanceiroInstitutoRecursos: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFinanceiroInstitutoRecursos', 'id', id)
            const localVarPath = `/financeiro/GetFinanceiroInstitutoRecursos/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getId: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getId', 'id', id)
            const localVarPath = `/financeiro/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetIndicadoresContratos} getIndicadoresContratos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndicadoresTomador: async (getIndicadoresContratos: GetIndicadoresContratos, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getIndicadoresContratos' is not null or undefined
            assertParamExists('getIndicadoresTomador', 'getIndicadoresContratos', getIndicadoresContratos)
            const localVarPath = `/financeiro/GetIndicadoresContratos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getIndicadoresContratos, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagamentosPendentes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financeiro/GetPagamentosPendentes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPesquisarLancamentos: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pesquisar' is not null or undefined
            assertParamExists('getPesquisarLancamentos', 'pesquisar', pesquisar)
            const localVarPath = `/financeiro/GetPesquisarLancamentos`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatorioCapitalAplicadoXRentabilidade: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financeiro/getRelatorioCapitalAplicadoXRentabilidade`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatorioContrato: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pesquisar' is not null or undefined
            assertParamExists('getRelatorioContrato', 'pesquisar', pesquisar)
            const localVarPath = `/financeiro/GetRelatorioContrato`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatorioGRPE: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pesquisar' is not null or undefined
            assertParamExists('getRelatorioGRPE', 'pesquisar', pesquisar)
            const localVarPath = `/financeiro/GetRelatorioGRPE`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatorioIOF: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financeiro/GetRelatorioIOF`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatorioLanc: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pesquisar' is not null or undefined
            assertParamExists('getRelatorioLanc', 'pesquisar', pesquisar)
            const localVarPath = `/financeiro/GetRelatorioLanc`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getbuscaParcelas: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getbuscaParcelas', 'id', id)
            const localVarPath = `/financeiro/GetbuscaParcelas/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getpesquisarContrato: async (pesquisar: Pesquisar, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pesquisar' is not null or undefined
            assertParamExists('getpesquisarContrato', 'pesquisar', pesquisar)
            const localVarPath = `/financeiro/GetpesquisarContrato`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(pesquisar, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        novoContrato: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financeiro/novoContrato`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pesquisarContratoGerado: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financeiro/pesquisarContratoGerado`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processaPDF: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financeiro/processaPdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCancelarContratoPendente: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/financeiro/putCancelarContratoPendente`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContratoPendente: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putContratoPendente', 'id', id)
            const localVarPath = `/financeiro/putContratoPendente/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLancamento: async (financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'financeiroCompetenciaParcelas' is not null or undefined
            assertParamExists('putLancamento', 'financeiroCompetenciaParcelas', financeiroCompetenciaParcelas)
            const localVarPath = `/financeiro/putLancamento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(financeiroCompetenciaParcelas, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GeraGRPE} geraGRPE 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unPutGRPE: async (geraGRPE: GeraGRPE, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'geraGRPE' is not null or undefined
            assertParamExists('unPutGRPE', 'geraGRPE', geraGRPE)
            const localVarPath = `/financeiro/unPutGRPE`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(geraGRPE, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unPutLancamento: async (financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'financeiroCompetenciaParcelas' is not null or undefined
            assertParamExists('unPutLancamento', 'financeiroCompetenciaParcelas', financeiroCompetenciaParcelas)
            const localVarPath = `/financeiro/unPutLancamento`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(financeiroCompetenciaParcelas, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FinanceiroApi - functional programming interface
 * @export
 */
export const FinanceiroApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FinanceiroApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async carregarProcessoVirtual(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.carregarProcessoVirtual(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.carregarProcessoVirtual']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async consultaDeParcelasPendentes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.consultaDeParcelasPendentes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.consultaDeParcelasPendentes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async criaRemessaBancaria(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.criaRemessaBancaria(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.criaRemessaBancaria']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDocumento(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDocumento(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.deleteDocumento']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {DocumentacaoProcessoVirtual} documentacaoProcessoVirtual 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentacaoProcesso(documentacaoProcessoVirtual: DocumentacaoProcessoVirtual, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentacaoProcesso(documentacaoProcessoVirtual, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.documentacaoProcesso']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {DocumentacaoProcessoVirtual} documentacaoProcessoVirtual 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async documentacaoProcessoPdf(documentacaoProcessoVirtual: DocumentacaoProcessoVirtual, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.documentacaoProcessoPdf(documentacaoProcessoVirtual, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.documentacaoProcessoPdf']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async download(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.download(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.download']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContratoPelaProposta(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContratoPelaProposta(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.getContratoPelaProposta']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getEndpoint(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getEndpoint(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.getEndpoint']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFinanceiroInstitutoRecursos(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFinanceiroInstitutoRecursos(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.getFinanceiroInstitutoRecursos']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getId(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getId(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.getId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GetIndicadoresContratos} getIndicadoresContratos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getIndicadoresTomador(getIndicadoresContratos: GetIndicadoresContratos, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getIndicadoresTomador(getIndicadoresContratos, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.getIndicadoresTomador']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPagamentosPendentes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPagamentosPendentes(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.getPagamentosPendentes']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPesquisarLancamentos(pesquisar: Pesquisar, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPesquisarLancamentos(pesquisar, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.getPesquisarLancamentos']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatorioCapitalAplicadoXRentabilidade(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatorioCapitalAplicadoXRentabilidade(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.getRelatorioCapitalAplicadoXRentabilidade']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatorioContrato(pesquisar: Pesquisar, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatorioContrato(pesquisar, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.getRelatorioContrato']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatorioGRPE(pesquisar: Pesquisar, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatorioGRPE(pesquisar, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.getRelatorioGRPE']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatorioIOF(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatorioIOF(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.getRelatorioIOF']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRelatorioLanc(pesquisar: Pesquisar, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRelatorioLanc(pesquisar, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.getRelatorioLanc']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getbuscaParcelas(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getbuscaParcelas(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.getbuscaParcelas']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getpesquisarContrato(pesquisar: Pesquisar, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getpesquisarContrato(pesquisar, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.getpesquisarContrato']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async novoContrato(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.novoContrato(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.novoContrato']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pesquisarContratoGerado(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pesquisarContratoGerado(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.pesquisarContratoGerado']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async processaPDF(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.processaPDF(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.processaPDF']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putCancelarContratoPendente(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putCancelarContratoPendente(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.putCancelarContratoPendente']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putContratoPendente(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putContratoPendente(id, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.putContratoPendente']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLancamento(financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLancamento(financeiroCompetenciaParcelas, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.putLancamento']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {GeraGRPE} geraGRPE 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unPutGRPE(geraGRPE: GeraGRPE, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unPutGRPE(geraGRPE, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.unPutGRPE']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unPutLancamento(financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unPutLancamento(financeiroCompetenciaParcelas, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['FinanceiroApi.unPutLancamento']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * FinanceiroApi - factory interface
 * @export
 */
export const FinanceiroApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FinanceiroApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        carregarProcessoVirtual(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.carregarProcessoVirtual(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        consultaDeParcelasPendentes(options?: any): AxiosPromise<void> {
            return localVarFp.consultaDeParcelasPendentes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        criaRemessaBancaria(options?: any): AxiosPromise<void> {
            return localVarFp.criaRemessaBancaria(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDocumento(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDocumento(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentacaoProcessoVirtual} documentacaoProcessoVirtual 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentacaoProcesso(documentacaoProcessoVirtual: DocumentacaoProcessoVirtual, options?: any): AxiosPromise<void> {
            return localVarFp.documentacaoProcesso(documentacaoProcessoVirtual, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DocumentacaoProcessoVirtual} documentacaoProcessoVirtual 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        documentacaoProcessoPdf(documentacaoProcessoVirtual: DocumentacaoProcessoVirtual, options?: any): AxiosPromise<void> {
            return localVarFp.documentacaoProcessoPdf(documentacaoProcessoVirtual, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        download(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.download(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContratoPelaProposta(options?: any): AxiosPromise<void> {
            return localVarFp.getContratoPelaProposta(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEndpoint(options?: any): AxiosPromise<void> {
            return localVarFp.getEndpoint(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFinanceiroInstitutoRecursos(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getFinanceiroInstitutoRecursos(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getId(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getId(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetIndicadoresContratos} getIndicadoresContratos 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIndicadoresTomador(getIndicadoresContratos: GetIndicadoresContratos, options?: any): AxiosPromise<void> {
            return localVarFp.getIndicadoresTomador(getIndicadoresContratos, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPagamentosPendentes(options?: any): AxiosPromise<void> {
            return localVarFp.getPagamentosPendentes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPesquisarLancamentos(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
            return localVarFp.getPesquisarLancamentos(pesquisar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatorioCapitalAplicadoXRentabilidade(options?: any): AxiosPromise<void> {
            return localVarFp.getRelatorioCapitalAplicadoXRentabilidade(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatorioContrato(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
            return localVarFp.getRelatorioContrato(pesquisar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatorioGRPE(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
            return localVarFp.getRelatorioGRPE(pesquisar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatorioIOF(options?: any): AxiosPromise<void> {
            return localVarFp.getRelatorioIOF(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRelatorioLanc(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
            return localVarFp.getRelatorioLanc(pesquisar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getbuscaParcelas(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.getbuscaParcelas(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Pesquisar} pesquisar 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getpesquisarContrato(pesquisar: Pesquisar, options?: any): AxiosPromise<void> {
            return localVarFp.getpesquisarContrato(pesquisar, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        novoContrato(options?: any): AxiosPromise<void> {
            return localVarFp.novoContrato(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pesquisarContratoGerado(options?: any): AxiosPromise<void> {
            return localVarFp.pesquisarContratoGerado(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processaPDF(options?: any): AxiosPromise<void> {
            return localVarFp.processaPDF(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putCancelarContratoPendente(options?: any): AxiosPromise<void> {
            return localVarFp.putCancelarContratoPendente(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putContratoPendente(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.putContratoPendente(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLancamento(financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas, options?: any): AxiosPromise<void> {
            return localVarFp.putLancamento(financeiroCompetenciaParcelas, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GeraGRPE} geraGRPE 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unPutGRPE(geraGRPE: GeraGRPE, options?: any): AxiosPromise<void> {
            return localVarFp.unPutGRPE(geraGRPE, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unPutLancamento(financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas, options?: any): AxiosPromise<void> {
            return localVarFp.unPutLancamento(financeiroCompetenciaParcelas, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FinanceiroApi - object-oriented interface
 * @export
 * @class FinanceiroApi
 * @extends {BaseAPI}
 */
export class FinanceiroApi extends BaseAPI {
    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public carregarProcessoVirtual(id: number, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).carregarProcessoVirtual(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public consultaDeParcelasPendentes(options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).consultaDeParcelasPendentes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public criaRemessaBancaria(options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).criaRemessaBancaria(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public deleteDocumento(id: number, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).deleteDocumento(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentacaoProcessoVirtual} documentacaoProcessoVirtual 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public documentacaoProcesso(documentacaoProcessoVirtual: DocumentacaoProcessoVirtual, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).documentacaoProcesso(documentacaoProcessoVirtual, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DocumentacaoProcessoVirtual} documentacaoProcessoVirtual 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public documentacaoProcessoPdf(documentacaoProcessoVirtual: DocumentacaoProcessoVirtual, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).documentacaoProcessoPdf(documentacaoProcessoVirtual, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public download(id: number, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).download(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public getContratoPelaProposta(options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).getContratoPelaProposta(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public getEndpoint(options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).getEndpoint(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public getFinanceiroInstitutoRecursos(id: number, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).getFinanceiroInstitutoRecursos(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public getId(id: number, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).getId(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetIndicadoresContratos} getIndicadoresContratos 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public getIndicadoresTomador(getIndicadoresContratos: GetIndicadoresContratos, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).getIndicadoresTomador(getIndicadoresContratos, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public getPagamentosPendentes(options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).getPagamentosPendentes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pesquisar} pesquisar 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public getPesquisarLancamentos(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).getPesquisarLancamentos(pesquisar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public getRelatorioCapitalAplicadoXRentabilidade(options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).getRelatorioCapitalAplicadoXRentabilidade(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pesquisar} pesquisar 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public getRelatorioContrato(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).getRelatorioContrato(pesquisar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pesquisar} pesquisar 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public getRelatorioGRPE(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).getRelatorioGRPE(pesquisar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public getRelatorioIOF(options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).getRelatorioIOF(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pesquisar} pesquisar 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public getRelatorioLanc(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).getRelatorioLanc(pesquisar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public getbuscaParcelas(id: number, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).getbuscaParcelas(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Pesquisar} pesquisar 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public getpesquisarContrato(pesquisar: Pesquisar, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).getpesquisarContrato(pesquisar, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public novoContrato(options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).novoContrato(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public pesquisarContratoGerado(options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).pesquisarContratoGerado(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public processaPDF(options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).processaPDF(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public putCancelarContratoPendente(options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).putCancelarContratoPendente(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public putContratoPendente(id: number, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).putContratoPendente(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public putLancamento(financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).putLancamento(financeiroCompetenciaParcelas, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GeraGRPE} geraGRPE 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public unPutGRPE(geraGRPE: GeraGRPE, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).unPutGRPE(geraGRPE, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FinanceiroCompetenciaParcelas} financeiroCompetenciaParcelas 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FinanceiroApi
     */
    public unPutLancamento(financeiroCompetenciaParcelas: FinanceiroCompetenciaParcelas, options?: RawAxiosRequestConfig) {
        return FinanceiroApiFp(this.configuration).unPutLancamento(financeiroCompetenciaParcelas, options).then((request) => request(this.axios, this.basePath));
    }
}

