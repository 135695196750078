import { pipe } from 'fp-ts/lib/function'
import { gerarDadosGuia, carregarArquivoGuia, criarGeradorDocxGuia } from './'
import PizZip from 'pizzip'
import { either } from 'fp-ts'
import docxtemplater from 'docxtemplater'

export async function gerarArquivoGuia(dados: ReturnType<typeof gerarDadosGuia>): Promise<either.Either<any, any>> {
  const arquivo = await carregarArquivoGuia()()

  const criarZip = (arquivo: any) => new PizZip(arquivo)

  const adicionarDadosGuia = (docx: docxtemplater, dados: any): docxtemplater => {
    docx.setData({ ...dados })
    return docx
  }

  const substituirDadosNoDocumento = (docx: docxtemplater): either.Either<any, docxtemplater> => {
    return either.tryCatch(
      () => {
        docx.render()
        return docx
      },
      (error) => {
        return {
          error,
          mensagem: 'Erro ao substituir dados no documento',
        }
      },
    )
  }

  const gerarArquivoDocx = (docx: docxtemplater): either.Either<any, any> => {
    return either.tryCatch(
      () => {
        return docx.getZip().generate({
          type: 'blob',
          mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        })
      },
      (error) => {
        return {
          error,
          mensagem: 'Erro ao gerar arquivo docx',
        }
      },
    )
  }

  return pipe(
    arquivo,
    either.map(criarZip),
    either.chain(criarGeradorDocxGuia),
    either.map((docx) => adicionarDadosGuia(docx, dados)),
    either.chain((docx) => substituirDadosNoDocumento(docx)),
    either.map(gerarArquivoDocx),
  )
}
